import {
    AdminReducerState,
    DeleteAdminActionTypes,
    GetAdminInfoActionTypes,
    GetAdminUserListActionTypes,
    LoginActionTypes,
    LogoutActionType,
} from "../../actions/admin/adminUser/types"
import { Reducer } from "redux"

const initialState: AdminReducerState = {
    access_token: "",
    email: "",
    id: null,
    list: {
        page: 1,
        count: 0,
        data: [],
    },
    loading: false,
    adminView: null,
}

export const adminReducer: Reducer<AdminReducerState, any> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case LoginActionTypes.LOGIN_REQUEST:
        case GetAdminUserListActionTypes.GET_ADMIN_USER_LIST_REQUEST:
        case GetAdminInfoActionTypes.GET_ADMIN_INFO_REQUEST:
            return { ...state, loading: true }

        case DeleteAdminActionTypes.DELETE_ADMIN_REQUEST:
            return { ...state }

        case LoginActionTypes.LOGIN_SUCCESS:
            return { ...initialState, ...payload, loading: false }

        case DeleteAdminActionTypes.DELETE_ADMIN_SUCCESS:
            return { ...state, loading: false }

        case GetAdminUserListActionTypes.GET_ADMIN_USER_LIST_SUCCESS:
            return { ...state, list: { ...payload }, loading: false }

        case GetAdminInfoActionTypes.GET_ADMIN_INFO_SUCCESS:
            return { ...state, adminView: { ...payload }, loading: false }

        case DeleteAdminActionTypes.DELETE_ADMIN_FAILURE:
        case GetAdminUserListActionTypes.GET_ADMIN_USER_LIST_FAILURE:
        case LoginActionTypes.LOGIN_FAILURE:
        case GetAdminInfoActionTypes.GET_ADMIN_INFO_FAILURE:
            return { ...state, loading: false }

        case LogoutActionType.LOGOUT:
            return { ...initialState }

        default:
            return state
    }
}
