import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetInfoPageByIdActionTypes } from "../../actions/admin/infoPage/types"
import { getInfoPageByIdActions } from "../../actions/admin/infoPage/infoPage"
import { call, put } from "redux-saga/effects"
import { getInfoPageById } from "../../../services/admin/infoPage"
import * as Sentry from "@sentry/node"
import { AppState } from "../../reducers"

export function* watchGetInfoPageByIdStart(): SagaIterator {
    yield takeEvery(GetInfoPageByIdActionTypes.GET_INFO_PAGE_BY_ID_REQUEST, getInfoPageByIdRequest)
}

export function* getInfoPageByIdRequest({
    payload,
}: ReturnType<typeof getInfoPageByIdActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getInfoPageById, { id: payload, access_token })

        yield put(getInfoPageByIdActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getInfoPageByIdActions.failure(e))
    }
}
