import { GetAdminUserListActionTypes } from "../../actions/admin/adminUser/types"
import { getAdminUserListActions } from "../../actions/admin/adminUser/admin"
import { getAdminList } from "../../../services/admin/admin"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "../../reducers"
import { call, put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"

export function* watchGetAminListStart(): SagaIterator {
    yield takeEvery(GetAdminUserListActionTypes.GET_ADMIN_USER_LIST_REQUEST, getAminListRequest)
}

export function* getAminListRequest({
    payload,
}: ReturnType<typeof getAdminUserListActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const {
            result: { data, count, page },
        } = yield call(getAdminList, { ...payload, access_token })

        yield put(getAdminUserListActions.success({ data, count, page }))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getAdminUserListActions.failure(e))
    }
}
