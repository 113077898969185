import { GetClaimsActionTypes } from "src/redux/actions/admin/ugc/types"
import { getClaimsActions } from "src/redux/actions/admin/ugc/ugc"
import { getClaims } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchGetClaimsStart(): SagaIterator {
    yield takeEvery(GetClaimsActionTypes.GET_CLAIMS_REQUEST, getClaimsRequest)
}

export function* getClaimsRequest({
    payload,
}: ReturnType<typeof getClaimsActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const {
            result: { data, count, page },
        } = yield call(getClaims, { ...payload, access_token })
        yield put(getClaimsActions.success({ data, count, page }))
    } catch (e) {
        yield put(getClaimsActions.failure(e.message))
        Sentry.captureException(e)
    }
}
