import { useDispatch } from "react-redux"
import { Field, Form, Formik, FormikHelpers } from "formik"
import { setNewPasswordActions } from "../../../../redux/actions/user/user"
import { setNewPasswordSchema } from "./SetNewPassword.schema"
import { StyledBodyMain, StyledSubText } from "../../../../styles/Text/Text.styles"
import { StyledSetNewPasswordModal } from "./SetNewPasswordModal.styles"
import CustomErrorMessage from "../../../../reusableComponents/CustomErrorMessage"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { SET_NEW_PASSWORD_MODAL, SIGN_IN_MODAL } from "../../../../constants/modals"
import { addModal, removeModal } from "../../../../redux/actions/ui/ui"
import SignInModal from "../SignInModal"
import InputPassword from "../../../../reusableComponents/Formik/InputPassword"

type Props = {
    reset_password_token: string
}

type FormValues = {
    password: string
}

const SetNewPasswordModal = ({ reset_password_token }: Props) => {
    const dispatch = useDispatch()
    const onSubmit = (value: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        dispatch(setNewPasswordActions.request({ ...value, reset_password_token, setErrors }))
    }

    const onCancel = () => {
        dispatch(removeModal(SET_NEW_PASSWORD_MODAL))
        dispatch(addModal({ id: SIGN_IN_MODAL, size: "auto", children: <SignInModal /> }))
    }

    return (
        <StyledSetNewPasswordModal>
            <div className="marginBottom">
                <StyledBodyMain weight={600}>Choose a new password.</StyledBodyMain>
                <StyledSubText color="grey">
                    Tip: the longer the better. Try mixing numbers and special characters (that's up
                    to you).
                </StyledSubText>
            </div>
            <Formik
                initialValues={{ password: "" }}
                validationSchema={setNewPasswordSchema}
                onSubmit={onSubmit}
            >
                {() => (
                    <Form>
                        <div className="marginBottom block">
                            <Field
                                type="password"
                                name="password"
                                as={InputPassword}
                                placeholder="Password"
                                block
                            />
                            <CustomErrorMessage name="password" component="div" />
                        </div>
                        <div className="buttonsBlock">
                            <ButtonOutlined onClick={onCancel} block>
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary type="submit" block>
                                Continue
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledSetNewPasswordModal>
    )
}

export default SetNewPasswordModal
