import createWebStorage from "redux-persist/lib/storage/createWebStorage"
import { isBrowser } from "../../helpers"

const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null)
        },
        setItem(_key, value) {
            return Promise.resolve(value)
        },
        removeItem(_key) {
            return Promise.resolve()
        },
    }
}

const storage = isBrowser() ? createWebStorage("local") : createNoopStorage()

export default storage
