import { SagaIterator } from "@redux-saga/types"
import { put, takeEvery } from "@redux-saga/core/effects"
import { ResetPasswordConfirmationActionTypes } from "../actions/user/types"
import { resetPasswordConfirmationActions } from "../actions/user/user"
import { call } from "redux-saga/effects"
import { resetPasswordConfirmCode } from "../../services/user"
import { addModal, removeModal } from "../actions/ui/ui"
import { FORGOT_PASSWORD_CONFIRMATION } from "../../constants/modals"
import { openSetNewPasswordModal } from "../../components/Header/Modals/SetNewPasswordModal/helpers"
import * as Sentry from "@sentry/node"

export function* watchResetPasswordConfirmationStart(): SagaIterator {
    yield takeEvery(
        ResetPasswordConfirmationActionTypes.RESET_PASSWORD_CONFIRMATION_REQUEST,
        resetPasswordConfirmationRequest
    )
}

export function* resetPasswordConfirmationRequest({
    payload: { setErrors, ...payload },
}: ReturnType<typeof resetPasswordConfirmationActions.request>): SagaIterator {
    try {
        const { result } = yield call(resetPasswordConfirmCode, payload)

        yield put(removeModal(FORGOT_PASSWORD_CONFIRMATION))
        yield put(addModal(openSetNewPasswordModal(result)))
        yield put(resetPasswordConfirmationActions.success())
    } catch (e) {
        Sentry.captureException(e)
        setErrors(e.result)
        yield put(resetPasswordConfirmationActions.failure(e.result))
    }
}
