import { Reducer } from "redux"
import {
    LoginActionTypes,
    LogoutActionType,
    ResetPasswordActionTypes,
    ResetPasswordConfirmationActionTypes,
    SendConfirmCodeActionTypes,
    SetUserActionTypes,
    ShareByEmailActionTypes,
    SignUpActionTypes,
    SsoSignOnActionTypes,
    UpdateAvatarActionTypes,
    UpdateUserNameActionTypes,
    UserReducerState,
    SendConfirmTokenActionTypes,
    PopupActionType,
    UnsubscribeActionTypes,
} from "../actions/user/types"

export const initialUserState: UserReducerState = {
    email: "",
    image: "",
    link: "",
    name: "",
    loading: false,
    loadingWholePage: false,
    access_token: "",
    isGuest: false,
    popup: null,
}

export const userReducer: Reducer<UserReducerState, any> = (
    state = initialUserState,
    { type, payload }
) => {
    switch (type) {
        case SignUpActionTypes.SIGN_UP_REQUEST:
        case LoginActionTypes.LOGIN_REQUEST:
        case SendConfirmCodeActionTypes.SEND_CONFIRM_CODE_REQUEST:
        case UnsubscribeActionTypes.UNSUBSCRIBE_REQUEST:
        case ResetPasswordActionTypes.RESET_PASSWORD_REQUEST:
        case ResetPasswordConfirmationActionTypes.RESET_PASSWORD_CONFIRMATION_REQUEST:
        case UpdateAvatarActionTypes.UPDATE_AVATAR_REQUEST:
        case SsoSignOnActionTypes.SSO_SIGN_ON_REQUEST:
            return { ...state, loading: true }

        case UpdateUserNameActionTypes.UPDATE_USER_NAME_REQUEST:
        case SendConfirmTokenActionTypes.SEND_CONFIRM_TOKEN_REQUEST:
            return { ...state }

        case ShareByEmailActionTypes.SHARE_BY_EMAIL_REQUEST:
            return { ...state, loadingWholePage: true }

        case UpdateAvatarActionTypes.UPDATE_AVATAR_SUCCESS:
        case UpdateUserNameActionTypes.UPDATE_USER_NAME_SUCCESS:
        case ResetPasswordActionTypes.RESET_PASSWORD_SUCCESS:
        case SendConfirmTokenActionTypes.SEND_CONFIRM_TOKEN_SUCCESS:
            return { ...state, ...payload, loading: false }

        case UnsubscribeActionTypes.UNSUBSCRIBE_SUCCESS: {
            return { ...state, loading: false }
        }

        case ShareByEmailActionTypes.SHARE_BY_EMAIL_SUCCESS:
            return { ...state, ...payload, loadingWholePage: false }

        case SignUpActionTypes.SIGN_UP_SUCCESS:
        case LoginActionTypes.LOGIN_SUCCESS:
        case SendConfirmCodeActionTypes.SEND_CONFIRM_CODE_SUCCESS:
        case ResetPasswordConfirmationActionTypes.RESET_PASSWORD_CONFIRMATION_SUCCESS:
        case SsoSignOnActionTypes.SSO_SIGN_ON_SUCCESS:
        case SetUserActionTypes.SET_USER:
            return { ...initialUserState, ...payload, loading: false }

        case SignUpActionTypes.SIGN_UP_FAILURE:
        case LoginActionTypes.LOGIN_FAILURE:
        case SendConfirmCodeActionTypes.SEND_CONFIRM_CODE_FAILURE:
        case ResetPasswordActionTypes.RESET_PASSWORD_FAILURE:
        case ResetPasswordConfirmationActionTypes.RESET_PASSWORD_CONFIRMATION_FAILURE:
        case UpdateAvatarActionTypes.UPDATE_AVATAR_FAILURE:
        case SsoSignOnActionTypes.SSO_SIGN_ON_FAILURE:
            return { ...state, loading: false }

        case UpdateUserNameActionTypes.UPDATE_USER_NAME_FAILURE:
        case SendConfirmTokenActionTypes.SEND_CONFIRM_TOKEN_FAILURE:
            return { ...state }

        case ShareByEmailActionTypes.SHARE_BY_EMAIL_FAILURE:
        case UnsubscribeActionTypes.UNSUBSCRIBE_FAILURE:
            return { ...state, loadingWholePage: false }

        case LogoutActionType.LOGOUT:
            return { ...initialUserState }

        case PopupActionType.SHOW_POPUP:
            return { ...state, popup: payload }

        case PopupActionType.REMOVE_POPUP:
            return { ...state, popup: null }

        default:
            return state
    }
}
