// Core
import { applyMiddleware, createStore, Store } from "redux"
import { assign } from "lodash"

// Middleware
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "redux-devtools-extension"

// Instruments
import rootSaga from "../sagas"
import rootReducer, { AppState } from "../reducers"
import { useMemo } from "react"
import { isBrowser } from "../../helpers"
import { persistStore } from "redux-persist"

let store: Store<AppState> | undefined

export const initStore = (preloadedState: AppState) => {
    const defaultState = preloadedState ? createStore(rootReducer).getState() : {}
    const currentState = assign(defaultState, preloadedState)

    const sagaMiddleware = createSagaMiddleware()
    const initedStore: any = createStore(
        rootReducer,
        currentState,
        composeWithDevTools(applyMiddleware(sagaMiddleware))
    )

    initedStore.sagaTask = sagaMiddleware.run(rootSaga)

    return initedStore
}

export const initializeStore = (preloadedState: AppState) => {
    let initializedStore = store || initStore(preloadedState)

    if (Object.keys(preloadedState) && store) {
        initializedStore = initStore(assign(store.getState(), preloadedState))

        store = undefined
    }

    if (!isBrowser()) {
        return initializedStore
    }

    if (!store) {
        store = initializedStore
    }

    return initializedStore
}

export const useStore = (initialState = {}) => {
    return useMemo(() => initializeStore(initialState as AppState), [JSON.stringify(initialState)])
}

export const persistor = persistStore(initStore({} as AppState))

export const setStore = (newStore: AppState) => {
    store = initStore(newStore || {})
}
