import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AssignCommentAnalystActionTypes } from "src/redux/actions/admin/ugc/types"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import { assignCommentAnalyst } from "src/services/admin/ugc"
import { assignCommentAnalystActions } from "src/redux/actions/admin/ugc/ugc"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* assignCommentAnalystStart(): SagaIterator {
    yield takeEvery(
        AssignCommentAnalystActionTypes.ASSIGN_COMMENT_ANALYST_REQUEST,
        assignCommentAnalystRequest
    )
}

export function* assignCommentAnalystRequest({
    payload: { ugc_id, analyst_id },
}: ReturnType<typeof assignCommentAnalystActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(assignCommentAnalyst, { ugc_id, analyst_id, access_token })

        yield put(assignCommentAnalystActions.success())

        message.success("Analyst has been assigned")
    } catch (e) {
        message.error("Something went wrong")
        Sentry.captureException(e)
        yield put(assignCommentAnalystActions.failure(e))
    }
}
