import styled from "styled-components"

export const StyledForgotPasswordConfirmationModal = styled.div`
    display: flex;
    flex-direction: column;

    margin: auto;
    .email {
        margin-left: 8px;
        flex: 1;

        & * {
            display: inline;
            word-wrap: break-word;
            font-size: 15px;
        }
    }

    .link {
        cursor: pointer;
    }

    .marginBottom {
        margin-bottom: 30px;
    }

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > * {
            width: 45%;
        }
    }
    .row {
        display: flex;
        width: 100%;

        input {
            height: fit-content;
            width: 45%;
        }
    }
    .stringRow {
        min-width: 581px;
    }

    @media (max-width: 767px) {
        width: 420px;
        .stringRow {
            min-width: auto;
        }
    }

    @media (max-width: 575px) {
        width: 100%;
    }
`
