import { SagaIterator } from "@redux-saga/types"
import { call, put, takeLatest } from "redux-saga/effects"
import { Replies, VoteActionTypes } from "../actions/main/types"
import { voteRequestActions } from "../actions/main/main"
import { postVote } from "../../services/main"
import { select } from "@redux-saga/core/effects"
import { AppState } from "../reducers"
import { Comment } from "../../types"
import * as Sentry from "@sentry/node"

const getReplies = (store: AppState) => store.main.replies || {}
const getComments = (store: AppState) => store.main.comments || {}

export function* watchVote(): SagaIterator {
    yield takeLatest(VoteActionTypes.VOTE_REQUEST, voteRequest)
}

export function* voteRequest({
    payload,
}: ReturnType<typeof voteRequestActions.request>): SagaIterator {
    try {
        const { result = {} } = yield call(postVote, payload)

        const replies: Replies = yield select(getReplies)
        const comments: Comment[] = yield select(getComments)

        const newComments = comments.map((comment: Comment) =>
            comment.id === payload.id ? { ...comment, votes: result } : comment
        )

        const newReplies: Replies = {}
        Object.entries(replies).forEach(([key, value]) => {
            if (!value || !value.length) {
                return
            }
            newReplies[+key] = value.map((reply) =>
                reply.id === payload.id ? { ...reply, votes: result } : reply
            )
        })

        yield put(voteRequestActions.success({ replies: newReplies, comments: newComments }))
    } catch (e) {
        Sentry.captureException(e)
        yield put(voteRequestActions.failure(e))
    }
}
