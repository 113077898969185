import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { DeleteAdminActionTypes } from "../../actions/admin/adminUser/types"
import { AppState } from "../../reducers"
import { put } from "redux-saga/effects"
import { deleteAdmin, getAdminList } from "../../../services/admin/admin"
import { deleteAdminActions, getAdminUserListActions } from "../../actions/admin/adminUser/admin"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* deleteAdminStart(): SagaIterator {
    yield takeEvery(DeleteAdminActionTypes.DELETE_ADMIN_REQUEST, deleteAdminRequest)
}

export function* deleteAdminRequest({
    payload: { id, action, page, limit },
}: ReturnType<typeof deleteAdminActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(deleteAdmin, { id, access_token })

        const {
            result: { data, count },
        } = yield call(getAdminList, { page: page, limit: limit, access_token })
        yield put(getAdminUserListActions.success({ data, count, page }))
        yield put(deleteAdminActions.success())
        if (action) {
            yield call(action)
        }
        message.success("Admin has been deleted")
    } catch (e) {
        message.error("Something went wrong")
        Sentry.captureException(e)
        yield put(deleteAdminActions.failure(e))
    }
}
