export type UGCReducerState = {
    abuseReports: AbuseReportsList
    analysisReports: AnalysisReportsList
    loading: boolean
    error: any
    analysisTopics: AnalysisTopicsListItem[]
    analysts: AnalystsList
    claims: ClaimsList
    claimsTopics: ClaimTopicsListItem[]
    commentsStatuses: string[] | null
    abuseReportsStatuses: string[] | null
}

export type AbuseReportListItem = {
    id: number
    author_name: string
    create_at: Date
    category: number
    suggest_body: {
        text?: string
        question?: string
    }
    ugc_author_avatar: string
    ugc_author_name: string | null
}

export type AnalysisReportListItem = {
    ugc_id: number
    short_name: string
    created_at: Date
    submitted_by: string
    data: {
        text: string
    }
}

export type AbuseReportsList = {
    page: number
    count: number
    data: AbuseReportListItem[]
}

export type AnalysisReportsList = {
    page: number
    count: number
    data: AnalysisReportListItem[]
}

export type AnalysisTopicsListItem = {
    id: number
    name: string
}

export type AnalysisTopicsList = AnalysisTopicsListItem[]

export type AnalystItem = {
    id: number
    email: string
}

export type AnalystsList = AnalystItem[]

export type ClaimListItem = {
    ugc_id: number
    short_name: string
    created_at: Date
    submitted_by: string
    data: {
        text: string
    }
}

export type ClaimsList = {
    page: number
    count: number
    data: ClaimListItem[]
}

export type ClaimTopicsListItem = {
    id: number
    name: string
}

export type ClaimsTopicsList = ClaimTopicsListItem[]

export enum GetAbuseReportsActionTypes {
    GET_ABUSE_REPORTS_REQUEST = "@@admin/ugcInbox/GET_ABUSE_REPORTS_REQUEST",
    GET_ABUSE_REPORTS_SUCCESS = "@@admin/ugcInbox/GET_ABUSE_REPORTS_SUCCESS",
    GET_ABUSE_REPORTS_FAILURE = "@@admin/ugcInbox/GET_ABUSE_REPORTS_FAILURE",
}

export enum GetAnalysisReportsActionTypes {
    GET_ANALYSIS_REPORTS_REQUEST = "@@admin/ugcInbox/GET_ANALYSIS_REPORTS_REQUEST",
    GET_ANALYSIS_REPORTS_SUCCESS = "@@admin/ugcInbox/GET_ANALYSIS_REPORTS_SUCCESS",
    GET_ANALYSIS_REPORTS_FAILURE = "@@admin/ugcInbox/GET_ANALYSIS_REPORTS_FAILURE",
}

export enum GetAnalysisTopicsActionTypes {
    GET_ANALYSIS_TOPICS_REQUEST = "@@admin/ugcInbox/GET_ANALYSIS_TOPICS_REQUEST",
    GET_ANALYSIS_TOPICS_SUCCESS = "@@admin/ugcInbox/GET_ANALYSIS_TOPICS_SUCCESS",
    GET_ANALYSIS_TOPICS_FAILURE = "@@admin/ugcInbox/GET_ANALYSIS_TOPICS_FAILURE",
}

export enum GetAnalystsActionTypes {
    GET_ANALYSTS_REQUEST = "@@admin/ugcInbox/GET_ANALYSTS_REQUEST",
    GET_ANALYSTS_SUCCESS = "@@admin/ugcInbox/GET_ANALYSTS_SUCCESS",
    GET_ANALYSTS_FAILURE = "@@admin/ugcInbox/GET_ANALYSTS_FAILURE",
}

export enum GetClaimsActionTypes {
    GET_CLAIMS_REQUEST = "@@admin/ugcInbox/GET_CLAIMS_REQUEST",
    GET_CLAIMS_SUCCESS = "@@admin/ugcInbox/GET_CLAIMS_SUCCESS",
    GET_CLAIMS_FAILURE = "@@admin/ugcInbox/GET_CLAIMS_FAILURE",
}

export enum GetClaimsTopicsActionTypes {
    GET_CLAIMS_TOPICS_REQUEST = "@@admin/ugcInbox/GET_CLAIMS_TOPICS_REQUEST",
    GET_CLAIMS_TOPICS_SUCCESS = "@@admin/ugcInbox/GET_CLAIMS_TOPICS_SUCCESS",
    GET_CLAIMS_TOPICS_FAILURE = "@@admin/ugcInbox/GET_CLAIMS_TOPICS_FAILURE",
}

export enum RestoreCommentActionTypes {
    RESTORE_COMMENT_REQUEST = "@@admin/ugcInbox/RESTORE_COMMENT_REQUEST",
    RESTORE_COMMENT_SUCCESS = "@@admin/ugcInbox/RESTORE_COMMENT_SUCCESS",
    RESTORE_COMMENT_FAILURE = "@@admin/ugcInbox/RESTORE_COMMENT_FAILURE",
}

export enum AssignCommentAnalystActionTypes {
    ASSIGN_COMMENT_ANALYST_REQUEST = "@@admin/ugcInbox/ASSIGN_COMMENT_ANALYST_REQUEST",
    ASSIGN_COMMENT_ANALYST_SUCCESS = "@@admin/ugcInbox/ASSIGN_COMMENT_ANALYST_SUCCESS",
    ASSIGN_COMMENT_ANALYST_FAILURE = "@@admin/ugcInbox/ASSIGN_COMMENT_ANALYST_FAILURE",
}

export enum AssignReportAnalystActionTypes {
    ASSIGN_REPORT_ANALYST_REQUEST = "@@admin/ugcInbox/ASSIGN_REPORT_ANALYST_REQUEST",
    ASSIGN_REPORT_ANALYST_SUCCESS = "@@admin/ugcInbox/ASSIGN_REPORT_ANALYST_SUCCESS",
    ASSIGN_REPORT_ANALYST_FAILURE = "@@admin/ugcInbox/ASSIGN_REPORT_ANALYST_FAILURE",
}

export enum DeleteCommentActionTypes {
    DELETE_COMMENT_REQUEST = "@@admin/ugcInbox/DELETE_COMMENT_REQUEST",
    DELETE_COMMENT_SUCCESS = "@@admin/ugcInbox/DELETE_COMMENT_SUCCESS",
    DELETE_COMMENT_FAILURE = "@@admin/ugcInbox/DELETE_COMMENT_FAILURE",
}

export enum EditCommentActionTypes {
    EDIT_COMMENT_REQUEST = "@@main/EDIT_COMMENT_REQUEST",
    EDIT_COMMENT_SUCCESS = "@@main/EDIT_COMMENT_SUCCESS",
    EDIT_COMMENT_FAILURE = "@@main/EDIT_COMMENT_FAILURE",
}

export enum GetCommentsStatusesActionTypes {
    GET_COMMENTS_STATUSES_REQUEST = "@@admin/GET_COMMENTS_STATUSES_REQUEST",
    GET_COMMENTS_STATUSES_SUCCESS = "@@admin/GET_COMMENTS_STATUSES_SUCCESS",
    GET_COMMENTS_STATUSES_FAILURE = "@@admin/GET_COMMENTS_STATUSES_FAILURE",
}

export enum GetAbuseReportsStatusesActionTypes {
    GET_ABUSE_REPORTS_STATUSES_REQUEST = "@@admin/GET_ABUSE_REPORTS_STATUSES_REQUEST",
    GET_ABUSE_REPORTS_STATUSES_SUCCESS = "@@admin/GET_ABUSE_REPORTS_STATUSES_SUCCESS",
    GET_ABUSE_REPORTS_STATUSES_FAILURE = "@@admin/GET_ABUSE_REPORTS_STATUSES_FAILURE",
}

export enum SetCommentStatusActionTypes {
    SET_COMMENT_STATUS_REQUEST = "@@admin/SET_COMMENT_STATUS_REQUEST",
    SET_COMMENT_STATUS_SUCCESS = "@@admin/SET_COMMENT_STATUS_SUCCESS",
    SET_COMMENT_STATUS_FAILURE = "@@admin/SET_COMMENT_STATUS_FAILURE",
}

export enum SetAbuseReportStatusActionTypes {
    SET_ABUSE_REPORT_STATUS_REQUEST = "@@admin/SET_ABUSE_REPORT_STATUS_REQUEST",
    SET_ABUSE_REPORT_STATUS_SUCCESS = "@@admin/SET_ABUSE_REPORT_STATUS_SUCCESS",
    SET_ABUSE_REPORT_STATUS_FAILURE = "@@admin/SET_ABUSE_REPORT_STATUS_FAILURE",
}

export enum PostCommentReplyActionTypes {
    POST_COMMENT_REPLY_REQUEST = "@@main/POST_COMMENT_REPLY_REQUEST",
    POST_COMMENT_REPLY_SUCCESS = "@@main/POST_COMMENT_REPLY_SUCCESS",
    POST_COMMENT_REPLY_FAILURE = "@@main/POST_COMMENT_REPLY_FAILURE",
}
export enum HideReplyActionTypes {
    HIDE_REPLY_REQUEST = "@@main/HIDE_REPLY_REQUEST",
    HIDE_REPLY_SUCCESS = "@@main/HIDE_REPLY_SUCCESS",
    HIDE_REPLY_FAILURE = "@@main/HIDE_REPLY_FAILURE",
}
