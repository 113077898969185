import { createAsyncAction } from "typesafe-actions"
import {
    CreateInfoPageRequest,
    CreateInfoPageActionTypes,
    InfoPageType,
    UpdateInfoPageActionTypes,
    GetInfoPageByUrlActionTypes,
    GetInfoPageByIdActionTypes,
    GetInfoPageListActionTypes,
    InfoPageList,
    DeleteInfoPageActionTypes,
} from "./types"
import { Dispatch, SetStateAction } from "react"

export const createInfoPageActions = createAsyncAction(
    CreateInfoPageActionTypes.CREATE_INFO_PAGE_REQUEST,
    CreateInfoPageActionTypes.CREATE_INFO_PAGE_SUCCESS,
    CreateInfoPageActionTypes.CREATE_INFO_PAGE_FAILURE
)<CreateInfoPageRequest, undefined, { error: any }>()

export const updateInfoPageActions = createAsyncAction(
    UpdateInfoPageActionTypes.UPDATE_INFO_PAGE_REQUEST,
    UpdateInfoPageActionTypes.UPDATE_INFO_PAGE_SUCCESS,
    UpdateInfoPageActionTypes.UPDATE_INFO_PAGE_FAILURE
)<InfoPageType, InfoPageType, { error: any }>()

export const getInfoPageByUrlActions = createAsyncAction(
    GetInfoPageByUrlActionTypes.GET_INFO_PAGE_BY_URL_REQUEST,
    GetInfoPageByUrlActionTypes.GET_INFO_PAGE_BY_URL_SUCCESS,
    GetInfoPageByUrlActionTypes.GET_INFO_PAGE_BY_URL_FAILURE
)<
    { slug: string; setStatusCode?: Dispatch<SetStateAction<number | null>> },
    InfoPageType,
    { error: any }
>()

export const getInfoPageByIdActions = createAsyncAction(
    GetInfoPageByIdActionTypes.GET_INFO_PAGE_BY_ID_REQUEST,
    GetInfoPageByIdActionTypes.GET_INFO_PAGE_BY_ID_SUCCESS,
    GetInfoPageByIdActionTypes.GET_INFO_PAGE_BY_ID_FAILURE
)<number, InfoPageType, { error: any }>()

export const getInfoPageListActions = createAsyncAction(
    GetInfoPageListActionTypes.GET_INFO_PAGE_LIST_REQUEST,
    GetInfoPageListActionTypes.GET_INFO_PAGE_LIST_SUCCESS,
    GetInfoPageListActionTypes.GET_INFO_PAGE_LIST_FAILURE
)<{ limit: number; page: number }, InfoPageList, { error: any }>()

export const deleteInfoPageActions = createAsyncAction(
    DeleteInfoPageActionTypes.DELETE_INFO_PAGE_REQUEST,
    DeleteInfoPageActionTypes.DELETE_INFO_PAGE_SUCCESS,
    DeleteInfoPageActionTypes.DELETE_INFO_PAGE_FAILURE
)<{ id: number; action?: () => void }, undefined, { error: any }>()
