import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { call, put } from "redux-saga/effects"

// utils
import { GetNodeFunctionsActionTypes } from "src/redux/actions/admin/analysis/types"
import { getNodeFunctionsActions } from "src/redux/actions/admin/analysis/analysis"
import { getNodeFunctions } from "src/services/admin/analysis"

// ui
import { message } from "antd"

export function* watchGetNodeFunctions(): SagaIterator {
    yield takeEvery(GetNodeFunctionsActionTypes.GET_NODE_FUNCTIONS_REQUEST, getNodeFunctionsRequest)
}

export function* getNodeFunctionsRequest(): SagaIterator {
    try {
        const { result } = yield call(getNodeFunctions)
        yield put(getNodeFunctionsActions.success(result))
    } catch (e) {
        yield put(getNodeFunctionsActions.failure(e.message))
        message.error(e.message)
    }
}
