import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { DeleteInfoPageActionTypes } from "../../actions/admin/infoPage/types"
import { deleteInfoPageActions } from "../../actions/admin/infoPage/infoPage"
import { call, put } from "redux-saga/effects"
import { deleteInfoPage } from "../../../services/admin/infoPage"
import { message } from "antd"
import { AppState } from "../../reducers"

export function* watchDeleteInfoPageStart(): SagaIterator {
    yield takeEvery(DeleteInfoPageActionTypes.DELETE_INFO_PAGE_REQUEST, deleteInfoPageRequest)
}

export function* deleteInfoPageRequest({
    payload,
}: ReturnType<typeof deleteInfoPageActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        yield call(deleteInfoPage, { id: payload.id, access_token })

        yield put(deleteInfoPageActions.success())

        if (payload.action) {
            yield call(payload.action)
        }

        message.success("Info page was deleted")
    } catch (e) {
        yield put(deleteInfoPageActions.failure(e.result))
        message.error(e.message)
    }
}
