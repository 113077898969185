import { SagaIterator } from "@redux-saga/types"
import { call, put, takeLatest } from "redux-saga/effects"
import { SendConfirmCodeActionTypes } from "../actions/user/types"
import { sendConfirmCodeActions, signUpActions } from "../actions/user/user"
import { sendConfirmCode } from "../../services/user"
import { addModal, removeModal } from "../actions/ui/ui"
import { ENTER_CODE_MODAL } from "../../constants/modals"
import { openSuccessLoginModal } from "../../components/Header/Modals/SuccessLoginModal/helpers"
import * as Sentry from "@sentry/node"
import { errorCodes } from "../../constants/errorCodes"

export function* watchSendConfirmCodeStart(): SagaIterator {
    yield takeLatest(SendConfirmCodeActionTypes.SEND_CONFIRM_CODE_REQUEST, sendConfirmCodeRequest)
}

export function* sendConfirmCodeRequest({
    payload: { setErrors, ...payload },
}: ReturnType<typeof sendConfirmCodeActions.request>) {
    try {
        const { result } = yield call(sendConfirmCode, payload)

        yield put(sendConfirmCodeActions.success(result))
        yield put(removeModal(ENTER_CODE_MODAL))
        yield put(addModal(openSuccessLoginModal))
    } catch (e) {
        Sentry.captureException(e)
        setErrors(e.result)
        yield put(signUpActions.failure(e.result))
        if (e?.result?.errorCode === errorCodes.EMAIL_ALREADY_CONFIRMED) {
            yield put(removeModal(ENTER_CODE_MODAL))
            yield put(addModal(openSuccessLoginModal))
        }
    }
}
