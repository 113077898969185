import { GetAnalysisReportsActionTypes } from "src/redux/actions/admin/ugc/types"
import { getAnalysisReportsActions } from "src/redux/actions/admin/ugc/ugc"
import { getAnalysisReports } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchGetAnalysisReportsStart(): SagaIterator {
    yield takeEvery(
        GetAnalysisReportsActionTypes.GET_ANALYSIS_REPORTS_REQUEST,
        getAnalysisReportsRequest
    )
}

export function* getAnalysisReportsRequest({
    payload,
}: ReturnType<typeof getAnalysisReportsActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const {
            result: { data, count, page },
        } = yield call(getAnalysisReports, { ...payload, access_token })
        yield put(getAnalysisReportsActions.success({ data, count, page }))
    } catch (e) {
        yield put(getAnalysisReportsActions.failure(e.message))
        Sentry.captureException(e)
    }
}
