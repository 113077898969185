import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetAdminInfoActionTypes } from "../../actions/admin/adminUser/types"
import { AppState } from "../../reducers"
import { call, put } from "redux-saga/effects"
import { getAdminInformation } from "../../../services/admin/admin"
import { getAdminInfoActions } from "../../actions/admin/adminUser/admin"
import * as Sentry from "@sentry/node"

export function* watchGetAminInformationStart(): SagaIterator {
    yield takeEvery(GetAdminInfoActionTypes.GET_ADMIN_INFO_REQUEST, getAminInformationRequest)
}

export function* getAminInformationRequest({
    payload: id,
}: ReturnType<typeof getAdminInfoActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getAdminInformation, { id, access_token })

        yield put(getAdminInfoActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getAdminInfoActions.failure(e))
    }
}
