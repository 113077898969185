import { GetAnalystsActionTypes } from "src/redux/actions/admin/ugc/types"
import { getAnalystsActionTypes } from "src/redux/actions/admin/ugc/ugc"
import { getAnalysts } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { call, put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"

export function* watchGetAnalystsStart(): SagaIterator {
    yield takeEvery(GetAnalystsActionTypes.GET_ANALYSTS_REQUEST, getAnalystsRequest)
}

export function* getAnalystsRequest(): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getAnalysts, { access_token })
        yield put(getAnalystsActionTypes.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getAnalystsActionTypes.failure(e))
    }
}
