import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { Replies, UserEditCommentActionTypes } from "../actions/main/types"
import { Comment } from "../../types"
import { put } from "redux-saga/effects"
import { editComment, editReply } from "../../services/main"
import { userEditCommentActions } from "../actions/main/main"
import { getCommentsSelector, getRepliesSelector } from "../../helpers/selectors"
import * as Sentry from "@sentry/node"
import { AppState } from "../reducers"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchUserEditComment(): SagaIterator {
    yield takeEvery(UserEditCommentActionTypes.USER_EDIT_COMMENT_REQUEST, userEditCommentRequest)
}

export function* userEditCommentRequest({
    payload: { setLoading, body, resetForm, setEditingComment, type },
}: ReturnType<typeof userEditCommentActions.request>): SagaIterator {
    try {
        setLoading(true)
        const { access_token } = yield select((state: AppState) => state?.user)

        if (type === "comment") {
            yield call(editComment, { text: body.text, id: body.commentId, access_token })
        }
        if (type === "reply") {
            yield call(editReply, { text: body.text, id: body.commentId, access_token })
        }

        const replies: Replies = yield select(getRepliesSelector)
        const comments: Comment[] = yield select(getCommentsSelector)

        const mapComment = (comment: Comment) =>
            comment.id === body.commentId ? { ...comment, text: body.text } : comment

        let mappedComments = comments
        if (type === "comment") {
            mappedComments = comments.map(mapComment)
        }

        let mappedReplies: Replies = replies
        if (type === "reply") {
            mappedReplies = {}

            Object.entries(replies).forEach(([key, comment]) => {
                mappedReplies[+key] = comment.map(mapComment)
            })
        }

        yield put(
            userEditCommentActions.success({ comments: mappedComments, replies: mappedReplies })
        )

        setEditingComment(false)
        resetForm && resetForm({})
    } catch (e) {
        Sentry.captureException(e)
        yield put(userEditCommentActions.failure(e))
    } finally {
        setLoading(false)
    }
}
