import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { call, put } from "redux-saga/effects"
import { ShareByEmailActionTypes } from "../actions/user/types"
import { shareByEmailActions } from "../actions/user/user"
import { shareByEmail } from "../../services/user"
import { removeModal } from "../actions/ui/ui"
import { SHARE_EMAIL_MODAL } from "../../constants/modals"
import * as Sentry from "@sentry/node"

export function* watchShareByEmail(): SagaIterator {
    yield takeEvery(ShareByEmailActionTypes.SHARE_BY_EMAIL_REQUEST, shareByEmailRequest)
}

export function* shareByEmailRequest(
    payload: ReturnType<typeof shareByEmailActions.request>
): SagaIterator {
    try {
        yield call(shareByEmail, payload)

        yield put(removeModal(SHARE_EMAIL_MODAL))
        yield put(shareByEmailActions.success(null))

        if (payload.payload.afterSend) {
            yield call(payload.payload.afterSend)
        }
    } catch (e) {
        Sentry.captureException(e)
        yield put(shareByEmailActions.failure(e))
    }
}
