import { takeEvery } from "@redux-saga/core/effects"
import { ResendConfirmEmailActionTypes } from "../actions/user/types"
import { call, put } from "redux-saga/effects"
import { resetPasswordConfirmationActions } from "../actions/user/user"
import { resendConfirmEmail } from "../../services/user"
import { addModal, removeAllModal } from "../actions/ui/ui"
import { addEmailResentModal } from "../../components/Header/Modals/EmailSentModal/helpers"
import * as Sentry from "@sentry/node"

export function* watchResendConfirmEmail() {
    yield takeEvery(
        ResendConfirmEmailActionTypes.RESEND_CONFIRM_EMAIL_REQUEST,
        resendConfirmEmailRequest
    )
}

export function* resendConfirmEmailRequest(
    payload: ReturnType<typeof resetPasswordConfirmationActions.request>
) {
    try {
        yield call(resendConfirmEmail, payload)

        yield put(removeAllModal())

        yield put(addModal(addEmailResentModal(payload.payload.email)))
        yield put(resetPasswordConfirmationActions.success())
    } catch (e) {
        Sentry.captureException(e)
        yield put(resetPasswordConfirmationActions.failure(e))
    }
}
