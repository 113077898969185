import { Reducer } from "redux"
import { ModalActionType, modalReducerState } from "../actions/ui/types"

export const initialModalState: modalReducerState = {
    modal: [],
}

export const modalReducer: Reducer<modalReducerState, any> = (
    state = initialModalState,
    { type, payload }
) => {
    switch (type) {
        case ModalActionType.ADD_MODAL:
            return {
                ...state,
                modal: [...state.modal, payload].filter(
                    ({ id }, index, arr) => arr.findIndex((modal) => modal.id === id) === index
                ),
            }
        case ModalActionType.REMOVE_MODAL:
            return {
                ...state,
                modal: state.modal.filter(({ id }) => id !== payload),
            }
        case ModalActionType.REMOVE_ALL_MODAL:
            return {
                ...state,
                modal: initialModalState.modal,
            }

        default:
            return state
    }
}
