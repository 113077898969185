import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { PostCommentActionTypes } from "../actions/main/types"
import { postCommentActions } from "../actions/main/main"
import { call, put } from "redux-saga/effects"
import { postComment } from "../../services/main"
import * as Sentry from "@sentry/node"

export function* watchPostComment(): SagaIterator {
    yield takeEvery(PostCommentActionTypes.POST_COMMENT_REQUEST, postCommentRequest)
}

export function* postCommentRequest({
    payload: { resetForm, ...payload },
}: ReturnType<typeof postCommentActions.request>) {
    try {
        const { result } = yield call(postComment, payload)
        resetForm()
        yield put(postCommentActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(postCommentActions.failure(e))
    }
}
