import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { DeleteUserActionTypes } from "src/redux/actions/admin/user/types"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import { deleteUser, getUserList } from "src/services/admin/user"
import { deleteUserActions, getUserListActions } from "src/redux/actions/admin/user/user"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* deleteUserStart(): SagaIterator {
    yield takeEvery(DeleteUserActionTypes.DELETE_USER_REQUEST, deleteUserRequest)
}

export function* deleteUserRequest({
    payload: {
        id,
        page,
        limit,
        id_value,
        id_option,
        email_option,
        email_value,
        link_value,
        link_option,
        name_value,
        name_option,
        sign_in_count_option,
        sign_in_count_value,
        adin_user_email_value,
        admin_user_email_option,
    },
}: ReturnType<typeof deleteUserActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(deleteUser, { id, access_token })

        const {
            result: { data, count },
        } = yield call(getUserList, {
            page,
            limit,
            id_value,
            id_option,
            email_option,
            email_value,
            link_value,
            link_option,
            name_value,
            name_option,
            sign_in_count_option,
            sign_in_count_value,
            adin_user_email_value,
            admin_user_email_option,
            access_token,
        })
        yield put(getUserListActions.success({ data, count, page }))

        yield put(deleteUserActions.success())

        message.success("User has been deleted")
    } catch (e) {
        message.error("Something went wrong")
        Sentry.captureException(e)
        yield put(deleteUserActions.failure(e))
    }
}
