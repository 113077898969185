import { Reducer } from "redux"
import {
    RiddleAnswersActionType,
    SetActionActionType,
    SetEvidenceGroupActiveKeysActionType,
    SetHideHeaderActionType,
    SetStorylineActiveKeyActionType,
    uiReducerState,
} from "../actions/ui/types"

export const initialUiState: uiReducerState = {
    riddleAnswers: { 1: null, 2: null, 3: null, 4: null },
    hideHeader: false,
    storylineActiveKey: 0,
    evidenceGroupActiveKeys: {},
    action: null,
    isEdit: false,
}

export const uiReducer: Reducer<uiReducerState, any> = (
    state = initialUiState,
    { type, payload }
) => {
    switch (type) {
        case SetHideHeaderActionType.SET_HIDE_HEADER:
        case SetStorylineActiveKeyActionType.SET_STORYLINE_ACTIVE_KEY:
        case SetEvidenceGroupActiveKeysActionType.SET_EVIDENCE_ACTIVE_KEYS:
            return { ...state, ...payload }

        case RiddleAnswersActionType.SET_RIDDLE_ANSWERS:
            return {
                ...state,
                riddleAnswers: { ...state.riddleAnswers, ...payload },
            }

        case RiddleAnswersActionType.RESET_RIDDLE_ANSWERS:
            return { ...state, riddleAnswers: { ...initialUiState.riddleAnswers } }

        case SetActionActionType.SET_ACTION:
            return { ...state, ...payload }

        case SetActionActionType.REMOVE_ACTION:
            return { ...state, action: null }

        default:
            return state
    }
}
