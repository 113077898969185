import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { RestoreCommentActionTypes } from "src/redux/actions/admin/ugc/types"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import { restoreComment } from "src/services/admin/ugc"
import { restoreCommentActions } from "src/redux/actions/admin/ugc/ugc"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import * as Effects from "redux-saga/effects"
import { capitalizeFirstLetter } from "src/helpers/capitalizeFirstLetter"

const call: any = Effects.call

export function* restoreCommentStart(): SagaIterator {
    yield takeEvery(RestoreCommentActionTypes.RESTORE_COMMENT_REQUEST, restoreCommentRequest)
}

export function* restoreCommentRequest({
    payload: { id, type, action },
}: ReturnType<typeof restoreCommentActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(restoreComment, { id, type, access_token })

        yield put(restoreCommentActions.success())

        if (action) {
            action()
        }

        message.success(capitalizeFirstLetter(type) + " has been restored")
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(restoreCommentActions.failure(e))
    }
}
