import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetShortAnalysisListActionTypes } from "../actions/main/types"
import { call, put } from "redux-saga/effects"
import { getShortAnalysisList, getShortAnalysisListPublic } from "../../services"
import { getShortAnalysisActions } from "../actions/main/main"
import * as Sentry from "@sentry/node"
import { AppState } from "../reducers"
import { isEdit } from "../../helpers"

export function* getShortAnalysis(): SagaIterator {
    yield takeEvery(
        GetShortAnalysisListActionTypes.GET_SHORT_ANALYSIS_REQUEST,
        getShortAnalysisRequest
    )
}

export function* getShortAnalysisRequest({
    payload,
}: ReturnType<typeof getShortAnalysisActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)

        const { result } = isEdit()
            ? yield call(getShortAnalysisList, { ...payload, access_token })
            : yield call(getShortAnalysisListPublic, { ...payload })

        yield put(getShortAnalysisActions.success(result))

        setTimeout(() => (window.onscroll = null), 1)
    } catch (e) {
        Sentry.captureException(e)
        yield put(getShortAnalysisActions.failure(e))
    }
}
