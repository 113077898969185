import { combineReducers } from "redux"
import { StateType } from "typesafe-actions"
import { persistReducer } from "redux-persist"
import storage from "../store/storage"

import { userReducer as user } from "./userReducer"
import { adminReducer as admin } from "./admin/adminReducer"
import { mainReducer as main } from "./mainReducer"
import { uiReducer as ui } from "./uiReducer"
import { modalReducer as modal } from "./modalReducer"
import { infoPageReducer } from "./admin/infoPage"
import { analysisAdminReducer } from "./admin/analysisReducer"
import { usersReducer as users } from "./admin/usersReducer"
import { ugc } from "./admin/ugcReducer"

const userPersistConfig = {
    key: "user",
    storage,
    blacklist: ["error", "loading"],
}
const adminPersisConfig = {
    key: "adminUser",
    storage,
}
const usersPersisConfig = {
    key: "users",
    storage,
}

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, user),
    admin: persistReducer(adminPersisConfig, admin),
    users: persistReducer(usersPersisConfig, users),
    main,
    ui,
    modal,
    infoPageReducer,
    analysisAdminReducer,
    ugc,
})

export default rootReducer

export type AppState = StateType<typeof rootReducer>
