import { createAsyncAction } from "typesafe-actions"
import {
    DeleteAdminAnalysisActionTypes,
    GetAdminAnalysisActionTypes,
    GetAnalysisListActionTypes,
    AnalysisList,
    AnalysisListItem,
    UpdateAnalysisActionTypes,
    GetCalcDetailsActionTypes,
    GetNodeTreeActionTypes,
    GetNodeTypesActionTypes,
    GetNodeFunctionsActionTypes,
    SetAnalysisStatusActionTypes,
    UpdateAnalysisRequest,
    CopyAnalysisActionTypes,
} from "./types"
import { EvidenceType, CalcDetails, NodeTreeItem } from "src/types"

export const getAnalysisListActions = createAsyncAction(
    GetAnalysisListActionTypes.GET_ANALYSIS_LIST_REQUEST,
    GetAnalysisListActionTypes.GET_ANALYSIS_LIST_SUCCESS,
    GetAnalysisListActionTypes.GET_ANALYSIS_LIST_FAILURE
)<{ limit: number; page: number }, AnalysisList, { error: any }>()

export const getAdminAnalysisActions = createAsyncAction(
    GetAdminAnalysisActionTypes.GET_ADMIN_ANALYSIS_REQUEST,
    GetAdminAnalysisActionTypes.GET_ADMIN_ANALYSIS_SUCCESS,
    GetAdminAnalysisActionTypes.GET_ADMIN_ANALYSIS_FAILURE
)<string | string[], EvidenceType, { error: any }>()

export const deleteAdminAnalysisActions = createAsyncAction(
    DeleteAdminAnalysisActionTypes.DELETE_ADMIN_ANALYSIS_REQUEST,
    DeleteAdminAnalysisActionTypes.DELETE_ADMIN_ANALYSIS_SUCCESS,
    DeleteAdminAnalysisActionTypes.DELETE_ADMIN_ANALYSIS_FAILURE
)<
    { id: number | string; action?: () => void; page?: number; limit?: number | undefined },
    undefined,
    { error: any }
>()

export const updateAnalysisActions = createAsyncAction(
    UpdateAnalysisActionTypes.UPDATE_ANALYSIS_REQUEST,
    UpdateAnalysisActionTypes.UPDATE_ANALYSIS_SUCCESS,
    UpdateAnalysisActionTypes.UPDATE_ANALYSIS_FAILURE
)<UpdateAnalysisRequest, AnalysisListItem, { error: any }>()

export const getCalcDetailsActions = createAsyncAction(
    GetCalcDetailsActionTypes.GET_CALC_DETAILS_REQUEST,
    GetCalcDetailsActionTypes.GET_CALC_DETAILS_SUCCESS,
    GetCalcDetailsActionTypes.GET_CALC_DETAILS_FAILURE
)<{ id: number }, CalcDetails, { error: any }>()

export const getNodeTreeActions = createAsyncAction(
    GetNodeTreeActionTypes.GET_NODE_TREE_REQUEST,
    GetNodeTreeActionTypes.GET_NODE_TREE_SUCCESS,
    GetNodeTreeActionTypes.GET_NODE_TREE_FAILURE
)<
    { id: number; callback?: any; callback2?: any },
    { id: number; data: NodeTreeItem },
    { error: any }
>()

export const getNodeTypesActions = createAsyncAction(
    GetNodeTypesActionTypes.GET_NODE_TYPES_REQUEST,
    GetNodeTypesActionTypes.GET_NODE_TYPES_SUCCESS,
    GetNodeTypesActionTypes.GET_NODE_TYPES_FAILURE
)<undefined, string[], { error: any }>()

export const getNodeFunctionsActions = createAsyncAction(
    GetNodeFunctionsActionTypes.GET_NODE_FUNCTIONS_REQUEST,
    GetNodeFunctionsActionTypes.GET_NODE_FUNCTIONS_SUCCESS,
    GetNodeFunctionsActionTypes.GET_NODE_FUNCTIONS_FAILURE
)<undefined, string[], { error: any }>()

export const setAnalysisStatusActions = createAsyncAction(
    SetAnalysisStatusActionTypes.SET_ANALYSIS_STATUS_REQUEST,
    SetAnalysisStatusActionTypes.SET_ANALYSIS_STATUS_SUCCESS,
    SetAnalysisStatusActionTypes.SET_ANALYSIS_STATUS_FAILURE
)<{ entity_id: number | undefined }, any, { error: any }>()

export const copyAnalysisActions = createAsyncAction(
    CopyAnalysisActionTypes.COPY_ANALYSIS_REQUEST,
    CopyAnalysisActionTypes.COPY_ANALYSIS_SUCCESS,
    CopyAnalysisActionTypes.COPY_ANALYSIS_FAILURE
)<{ analysisId: number; action: () => void }, undefined, { error: any }>()
