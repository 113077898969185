import { SagaIterator } from "@redux-saga/types"
import { LoginActionTypes } from "../actions/user/types"
import { loginActions } from "../actions/user/user"
import { put, select, takeEvery } from "@redux-saga/core/effects"
import { call } from "redux-saga/effects"
import { login } from "../../services/user"
import { removeAction, removeModal } from "../actions/ui/ui"
import { SIGN_IN_MODAL } from "../../constants/modals"
import { setStore } from "../store"
import * as Sentry from "@sentry/node"
import { AppState } from "../reducers"

export function* watchLoginStart(): SagaIterator {
    yield takeEvery(LoginActionTypes.LOGIN_REQUEST, loginRequest)
}

export function* loginRequest({
    payload: { setErrors, ...payload },
}: ReturnType<typeof loginActions.request>): SagaIterator {
    try {
        const { result } = yield call(login, payload)

        yield put(loginActions.success(result))
        yield put(removeModal(SIGN_IN_MODAL))

        const action: () => void = yield select((state: AppState) => state.ui.action)

        const store = yield select((store) => store)
        yield call(setStore, store)

        if (action) {
            yield call(action)
            yield put(removeAction())
        }
    } catch (e) {
        Sentry.captureException(e)
        setErrors(e.result)
        yield put(loginActions.failure(e))
    }
}
