import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetUserInfoActionTypes } from "src/redux/actions/admin/user/types"
import { AppState } from "src/redux/reducers"
import { call, put } from "redux-saga/effects"
import { getUserInformation } from "src/services/admin/user"
import { getUserInfoActions } from "src/redux/actions/admin/user/user"
import * as Sentry from "@sentry/node"

export function* watchGetUserInformationStart(): SagaIterator {
    yield takeEvery(GetUserInfoActionTypes.GET_USER_INFO_REQUEST, getUserInformationRequest)
}

export function* getUserInformationRequest({
    payload: id,
}: ReturnType<typeof getUserInfoActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getUserInformation, { id, access_token })

        yield put(getUserInfoActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getUserInfoActions.failure(e))
    }
}
