import http from "./http"
import { postReportActions } from "src/redux/actions/main/main"

// types
import { Replies } from "src/redux/actions/main/types"
import { Comment, Story, Vote } from "../types"

export const getTopAnalysis = async ({
    device,
}: {
    device: "desktop" | "tablet" | "mobile"
}): Promise<{ result: Story[] }> => {
    return await http("analysis/top", { method: "GET", params: { device } })
}

export const getTopUnpublishedAnalysis = async ({
    device,
}: {
    device: "desktop" | "tablet" | "mobile"
}): Promise<{ result: Story[] }> => {
    return await http("analysis/top-short", {
        method: "GET",
        params: { device },
    })
}

export const getComments = async ({
    slug,
    limit = 5,
    offset = 0,
    type = "inference_objects",
}: {
    slug?: string
    limit?: number
    offset?: number
    type?: "inference_objects" | "claims"
}): Promise<{ result: { comments: Comment[]; comments_count: number; replies: Replies } }> => {
    if (type === "inference_objects") {
        const response: { result: { id: number } } = await http(`analysis/get-id/${slug}`, {
            method: "GET",
        })

        return await http(`comments/${response.result}`, {
            method: "GET",
            params: { limit, offset, type },
        })
    } else {
        return await http(`comments/${slug}`, {
            method: "GET",
            params: { limit, offset, type },
        })
    }
}

export const postComment = async ({
    slug,
    body,
    type = "inference_objects",
}: {
    slug: string
    body: { text: string }
    type?: "inference_objects" | "claims"
}): Promise<{ result: Comment }> => {
    return await http(`comments`, {
        method: "POST",
        body: JSON.stringify({ ...body, slug, type }),
        headers: { "Content-Type": "application/json" },
    })
}

export const postReply = async ({
    body: { text, commentId },
}: {
    body: { text: string; commentId: number }
}): Promise<{ result: Comment }> => {
    return await http(`replies/${commentId}`, {
        method: "POST",
        body: JSON.stringify({ text }),
        headers: { "Content-Type": "application/json" },
    })
}

export const postEditedComment = async ({
    body: { text, commentId },
    type,
    access_token,
}: {
    body: { text: string; commentId: number }
    type: string
    access_token: string
}): Promise<any> => {
    return await http(`comments/adminUser/edit/${commentId}`, {
        method: "PUT",
        body: JSON.stringify({ text, type }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const hideComment = async ({
    id,
    type,
    access_token,
}: {
    id: number
    type: string
    access_token: string
}): Promise<any> => {
    return await http(`comments/adminUser/delete/${id}`, {
        method: "DELETE",
        body: JSON.stringify({ type, id }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const getReplies = async ({
    id,
    limit,
    offset,
}: {
    id: number
    limit: number
    offset: number
}): Promise<{ result: Comment[] }> => {
    return await http(`replies/${id}`, { method: "GET", params: { limit, offset } })
}

export const postVote = async ({
    id,
    vote_flag,
    type,
}: {
    id: number
    vote_flag: boolean
    type: "comment" | "reply"
}): Promise<{
    result: Vote
}> => {
    return await http(`comments/vote/${id}`, {
        method: "POST",
        body: JSON.stringify({ vote_flag, type }),
        headers: { "Content-Type": "application/json" },
    })
}

export const postReport = async ({ payload }: ReturnType<typeof postReportActions.request>) => {
    return await http(`comments/report`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const editComment = async ({
    access_token,
    id,
    text,
}: {
    id: number
    text: string
    access_token: string
}): Promise<void> => {
    return await http(`comments`, {
        headers: { "Content-Type": "application/json" },
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({ id, text }),
    })
}

export const editReply = async ({
    access_token,
    id,
    text,
}: {
    id: number
    access_token: string
    text: string
}): Promise<void> => {
    return await http(`replies`, {
        headers: { "Content-Type": "application/json" },
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({ id, text }),
    })
}
