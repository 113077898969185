import {
    AdminAnalysisReducerState,
    DeleteAdminAnalysisActionTypes,
    GetAdminAnalysisActionTypes,
    GetAnalysisListActionTypes,
    UpdateAnalysisActionTypes,
    GetCalcDetailsActionTypes,
    GetNodeTreeActionTypes,
    GetNodeTypesActionTypes,
    GetNodeFunctionsActionTypes,
    SetAnalysisStatusActionTypes,
    CopyAnalysisActionTypes,
} from "src/redux/actions/admin/analysis/types"
import { Reducer } from "redux"

const initialState: AdminAnalysisReducerState = {
    analysis: null,
    loading: false,
    analysisList: {
        data: [],
        count: 0,
        page: 1,
    },
    analysisStatus: [],
    error: undefined,
    nodeTrees: [],
    nodeTypes: [],
    nodeFunctions: [],
}

export const analysisAdminReducer: Reducer<AdminAnalysisReducerState, any> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case GetAnalysisListActionTypes.GET_ANALYSIS_LIST_REQUEST:
        case GetAdminAnalysisActionTypes.GET_ADMIN_ANALYSIS_REQUEST:
        case DeleteAdminAnalysisActionTypes.DELETE_ADMIN_ANALYSIS_REQUEST:
            return { ...state, loading: true }

        case UpdateAnalysisActionTypes.UPDATE_ANALYSIS_REQUEST:
        case GetCalcDetailsActionTypes.GET_CALC_DETAILS_REQUEST:
        case GetNodeTreeActionTypes.GET_NODE_TREE_REQUEST:
        case GetNodeTypesActionTypes.GET_NODE_TYPES_REQUEST:
        case GetNodeFunctionsActionTypes.GET_NODE_FUNCTIONS_REQUEST:
        case SetAnalysisStatusActionTypes.SET_ANALYSIS_STATUS_REQUEST:
        case CopyAnalysisActionTypes.COPY_ANALYSIS_REQUEST:
        case CopyAnalysisActionTypes.COPY_ANALYSIS_SUCCESS:
            return { ...state }

        case DeleteAdminAnalysisActionTypes.DELETE_ADMIN_ANALYSIS_SUCCESS:
            return { ...state, loading: false }

        case GetAdminAnalysisActionTypes.GET_ADMIN_ANALYSIS_SUCCESS:
            return { ...state, analysis: payload, loading: false }

        case UpdateAnalysisActionTypes.UPDATE_ANALYSIS_SUCCESS:
            return {
                ...state,
                analysis: { ...state.analysis, errors: null, ...payload },
                loading: false,
            }

        case GetCalcDetailsActionTypes.GET_CALC_DETAILS_SUCCESS:
            return {
                ...state,
                analysis: { ...state.analysis, errors: null, calc_details: payload },
                loading: false,
            }

        case GetAnalysisListActionTypes.GET_ANALYSIS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                analysisList: payload,
            }

        case GetNodeTreeActionTypes.GET_NODE_TREE_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const existedTreeIndex = state.nodeTrees.findIndex((o) => o.id === payload.id)

            return {
                ...state,
                loading: false,
                nodeTrees:
                    existedTreeIndex >= 0
                        ? [...(state.nodeTrees[existedTreeIndex] = payload)]
                        : [...state.nodeTrees, payload],
            }

        case GetNodeTypesActionTypes.GET_NODE_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                nodeTypes: payload,
            }

        case GetNodeFunctionsActionTypes.GET_NODE_FUNCTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                nodeFunctions: payload,
            }

        case SetAnalysisStatusActionTypes.SET_ANALYSIS_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                analysisStatus: payload,
            }

        case DeleteAdminAnalysisActionTypes.DELETE_ADMIN_ANALYSIS_FAILURE:
        case GetAnalysisListActionTypes.GET_ANALYSIS_LIST_FAILURE:
        case GetAdminAnalysisActionTypes.GET_ADMIN_ANALYSIS_FAILURE:
            return { ...state, loading: false }

        case UpdateAnalysisActionTypes.UPDATE_ANALYSIS_FAILURE:
        case GetCalcDetailsActionTypes.GET_CALC_DETAILS_FAILURE:
        case GetNodeTreeActionTypes.GET_NODE_TREE_FAILURE:
        case GetNodeTypesActionTypes.GET_NODE_TYPES_FAILURE:
        case GetNodeFunctionsActionTypes.GET_NODE_FUNCTIONS_FAILURE:
        case SetAnalysisStatusActionTypes.SET_ANALYSIS_STATUS_FAILURE:
        case CopyAnalysisActionTypes.COPY_ANALYSIS_FAILURE:
            return { ...state, loading: false, error: payload }

        default:
            return state
    }
}
