import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetInfoPageListActionTypes } from "../../actions/admin/infoPage/types"
import { getInfoPageListActions } from "../../actions/admin/infoPage/infoPage"
import { call, put } from "redux-saga/effects"
import { getInfoPageList } from "../../../services/admin/infoPage"
import * as Sentry from "@sentry/node"
import { AppState } from "../../reducers"

export function* watchGetInfoPageListStart(): SagaIterator {
    yield takeEvery(GetInfoPageListActionTypes.GET_INFO_PAGE_LIST_REQUEST, getInfoPageListRequest)
}

export function* getInfoPageListRequest({
    payload,
}: ReturnType<typeof getInfoPageListActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const {
            result: { data, count, page },
        } = yield call(getInfoPageList, { ...payload, access_token })

        yield put(getInfoPageListActions.success({ data, count, page }))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getInfoPageListActions.failure(e))
    }
}
