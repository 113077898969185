import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { UpdateAnalysisActionTypes } from "../../actions/admin/analysis/types"
import { updateAnalysisActions } from "../../actions/admin/analysis/analysis"
import { call, put } from "redux-saga/effects"
import { updateAnalysis } from "../../../services/admin/analysis"
import { message } from "antd"
import { AppState } from "../../reducers"

export function* watchUpdateAnalaysisStart(): SagaIterator {
    yield takeEvery(UpdateAnalysisActionTypes.UPDATE_ANALYSIS_REQUEST, updateAnalysisRequest)
}

export function* updateAnalysisRequest({
    payload: { callback, ...restPayload },
}: ReturnType<typeof updateAnalysisActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(updateAnalysis, { ...restPayload, access_token })

        yield put(updateAnalysisActions.success(result))

        if (callback) {
            callback()
        }

        message.success("Analysis successfully updated")
    } catch (e) {
        yield put(updateAnalysisActions.failure(e.result))
        e.result
            ? e.result.map((item: any) => {
                  Object.keys(item).map((key) => {
                      message.error(item[key])
                  })
              })
            : message.error(e.message)
    }
}
