import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { SetAnalysisStatusActionTypes } from "src/redux/actions/admin/analysis/types"
import { setAnalysisStatusActions } from "src/redux/actions/admin/analysis/analysis"
import { put } from "redux-saga/effects"
import { setEntityStatus } from "src/services/admin/analysis"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"
import { AppState } from "src/redux/reducers"

const call: any = Effects.call

export function* watchSetAnalysisStatusStart(): SagaIterator {
    yield takeEvery(
        SetAnalysisStatusActionTypes.SET_ANALYSIS_STATUS_REQUEST,
        setAnalysisStatusRequest
    )
}

export function* setAnalysisStatusRequest({
    payload,
}: ReturnType<typeof setAnalysisStatusActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(setEntityStatus, {
            ...payload,
            entity: "analysis",
            access_token,
        })

        yield put(setAnalysisStatusActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(setAnalysisStatusActions.failure(e))
    }
}
