import { AdminUser, AdminList } from "../../redux/actions/admin/adminUser/types"
import http from "../http"

export const getAdminList = async ({
    access_token,
    page,
    limit,
}: {
    access_token: string
    page: number
    limit: number | string
}): Promise<{ result: AdminList }> => {
    return await http(`admin`, {
        method: "GET",
        params: { access_token, page, limit },
    })
}

export const createAdmin = async ({
    admin,
    access_token,
}: {
    admin: AdminUser
    access_token: string
}) => {
    return await http("admin", {
        method: "POST",
        body: JSON.stringify(admin),
        params: { access_token },
        headers: { "Content-Type": "application/json" },
    })
}

export const updateAdmin = async ({
    admin,
    access_token,
}: {
    admin: AdminUser
    access_token: string
}) => {
    return await http("admin", {
        method: "PUT",
        body: JSON.stringify(admin),
        params: { access_token },
        headers: { "Content-Type": "application/json" },
    })
}

export const getAdminInformation = async ({
    id,
    access_token,
}: {
    id: number | string
    access_token: string
}) => {
    return await http(`admin/${id}`, {
        method: "GET",
        params: { access_token },
    })
}

export const deleteAdmin = async ({
    id,
    access_token,
}: {
    id: number | string
    access_token: string
}) => {
    return await http(`admin/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}
