import {
    UsersReducerState,
    GetUserListActionTypes,
    GetUserInfoActionTypes,
    DeleteUserActionTypes,
} from "src/redux/actions/admin/user/types"
import { Reducer } from "redux"

const initialState: UsersReducerState = {
    access_token: "",
    email: "",
    id: null,
    list: {
        page: 1,
        count: 0,
        data: [],
    },
    loading: false,
    userView: null,
}

export const usersReducer: Reducer<UsersReducerState, any> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case GetUserListActionTypes.GET_USER_LIST_REQUEST:
        case GetUserInfoActionTypes.GET_USER_INFO_REQUEST:
            return { ...state, loading: true }

        case DeleteUserActionTypes.DELETE_USER_REQUEST:
            return { ...state }

        case DeleteUserActionTypes.DELETE_USER_SUCCESS:
            return { ...state, loading: false }

        case GetUserListActionTypes.GET_USER_LIST_SUCCESS:
            return { ...state, list: { ...payload }, loading: false }

        case GetUserInfoActionTypes.GET_USER_INFO_SUCCESS:
            return { ...state, userView: { ...payload }, loading: false }

        case GetUserListActionTypes.GET_USER_LIST_FAILURE:
        case GetUserInfoActionTypes.GET_USER_INFO_FAILURE:
        case DeleteUserActionTypes.DELETE_USER_FAILURE:
            return { ...state, loading: false }

        default:
            return state
    }
}
