import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { DeleteCommentActionTypes } from "src/redux/actions/admin/ugc/types"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import { deleteComment } from "src/services/admin/ugc"
import { deleteCommentActions } from "src/redux/actions/admin/ugc/ugc"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import * as Effects from "redux-saga/effects"
import { capitalizeFirstLetter } from "src/helpers/capitalizeFirstLetter"

const call: any = Effects.call

export function* deleteCommentStart(): SagaIterator {
    yield takeEvery(DeleteCommentActionTypes.DELETE_COMMENT_REQUEST, deleteCommentRequest)
}

export function* deleteCommentRequest({
    payload: { id, type, action },
}: ReturnType<typeof deleteCommentActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(deleteComment, { id, type, access_token })

        if (action) {
            action()
        }

        yield put(deleteCommentActions.success())
        message.success(capitalizeFirstLetter(type) + " has been deleted")
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(deleteCommentActions.failure(e))
    }
}
