import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { call, put } from "redux-saga/effects"
import { postEditedComment } from "src/services/main"
import { editCommentActions } from "src/redux/actions/admin/ugc/ugc"
import { EditCommentActionTypes } from "src/redux/actions/admin/ugc/types"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import { AppState } from "src/redux/reducers"
import { capitalizeFirstLetter } from "src/helpers/capitalizeFirstLetter"

export function* watchEditComment(): SagaIterator {
    yield takeEvery(EditCommentActionTypes.EDIT_COMMENT_REQUEST, editedCommentRequest)
}

export function* editedCommentRequest({
    payload: { body, type },
}: ReturnType<typeof editCommentActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(postEditedComment, { body, type, access_token })
        yield put(editCommentActions.success())

        message.success(capitalizeFirstLetter(type) + " has been edited")
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(editCommentActions.failure(e))
    }
}
