import {
    CreateInfoPageActionTypes,
    GetInfoPageByIdActionTypes,
    GetInfoPageByUrlActionTypes,
    GetInfoPageListActionTypes,
    InfoPageReducerState,
    UpdateInfoPageActionTypes,
    DeleteInfoPageActionTypes,
} from "../../actions/admin/infoPage/types"
import { Reducer } from "redux"

const initialState: InfoPageReducerState = {
    adminInfoPage: {
        id: null,
        unpublished: false,
        url: "",
        title: "",
        image_url_desktop: "",
        image_url_tablet: "",
        image_url_mobile: "",
        body: "",
        share_image_url: "",
        share_url: "",
        share_title: "",
        share_description: "",
        errors: undefined,
    },
    viewInfoPage: {
        id: null,
        unpublished: false,
        url: "",
        title: "",
        image_url_desktop: "",
        image_url_tablet: "",
        image_url_mobile: "",
        body: "",
        share_image_url: "",
        share_url: "",
        share_title: "",
        share_description: "",
    },
    infoPageList: {
        count: 0,
        page: 1,
        data: [],
    },
    loader: false,
}

export const infoPageReducer: Reducer<InfoPageReducerState, any> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case CreateInfoPageActionTypes.CREATE_INFO_PAGE_REQUEST:
        case UpdateInfoPageActionTypes.UPDATE_INFO_PAGE_REQUEST:
        case DeleteInfoPageActionTypes.DELETE_INFO_PAGE_REQUEST:
            return { ...state }

        case GetInfoPageByUrlActionTypes.GET_INFO_PAGE_BY_URL_REQUEST:
        case GetInfoPageByIdActionTypes.GET_INFO_PAGE_BY_ID_REQUEST:
        case GetInfoPageListActionTypes.GET_INFO_PAGE_LIST_REQUEST:
            return { ...state, loader: true }

        case GetInfoPageByIdActionTypes.GET_INFO_PAGE_BY_ID_SUCCESS:
        case UpdateInfoPageActionTypes.UPDATE_INFO_PAGE_SUCCESS:
        case DeleteInfoPageActionTypes.DELETE_INFO_PAGE_SUCCESS:
            return {
                ...state,
                adminInfoPage: { ...state.adminInfoPage, errors: null, ...payload },
                loader: false,
            }

        case GetInfoPageByUrlActionTypes.GET_INFO_PAGE_BY_URL_SUCCESS:
            return { ...state, viewInfoPage: { ...payload }, loader: false }

        case GetInfoPageListActionTypes.GET_INFO_PAGE_LIST_SUCCESS:
            return { ...state, infoPageList: { ...payload }, loader: false }

        case GetInfoPageListActionTypes.GET_INFO_PAGE_LIST_FAILURE:
        case GetInfoPageByIdActionTypes.GET_INFO_PAGE_BY_ID_FAILURE:
        case GetInfoPageByUrlActionTypes.GET_INFO_PAGE_BY_URL_FAILURE:
            return { ...state, ...payload, loader: false }

        case UpdateInfoPageActionTypes.UPDATE_INFO_PAGE_FAILURE:
        case DeleteInfoPageActionTypes.DELETE_INFO_PAGE_FAILURE:
            return { ...state, adminInfoPage: { ...state.adminInfoPage, errors: payload } }
        default:
            return state
    }
}
