import { SagaIterator } from "@redux-saga/types"
import { put, select, takeEvery } from "@redux-saga/core/effects"
import { call } from "redux-saga/effects"
import { loginAdmin } from "../../../services/user"
import { removeModal } from "../../actions/ui/ui"
import { SIGN_IN_MODAL } from "../../../constants/modals"
import { setStore } from "../../store"
import * as Sentry from "@sentry/node"
import { adminLoginActions } from "../../actions/admin/adminUser/admin"
import { LoginActionTypes } from "../../actions/admin/adminUser/types"

export function* watchAdminLoginStart(): SagaIterator {
    yield takeEvery(LoginActionTypes.LOGIN_REQUEST, adminLoginRequest)
}

export function* adminLoginRequest({
    payload: { setErrors, ...payload },
}: ReturnType<typeof adminLoginActions.request>): SagaIterator {
    try {
        const { result } = yield call(loginAdmin, payload)

        yield put(adminLoginActions.success(result))
        yield put(removeModal(SIGN_IN_MODAL))

        const store = yield select((store) => store)
        yield call(setStore, store)
    } catch (e) {
        Sentry.captureException(e)
        setErrors(e.result)
        yield put(adminLoginActions.failure(e))
    }
}
