import { SagaIterator } from "@redux-saga/types"
import { put, takeEvery } from "@redux-saga/core/effects"
import { ResetPasswordActionTypes } from "../actions/user/types"
import { resetPasswordActions } from "../actions/user/user"
import { call } from "redux-saga/effects"
import { resetPassword } from "../../services/user"
import { removeModal } from "../actions/ui/ui"
import { FORGOT_PASSWORD_MODAL } from "../../constants/modals"
import { openForgotPasswordConfirmationModal } from "../../components/Header/Modals/ForgotPasswordConfirmationModal/helpers"
import * as Sentry from "@sentry/node"

export function* watchResetPasswordStart(): SagaIterator {
    yield takeEvery(ResetPasswordActionTypes.RESET_PASSWORD_REQUEST, resetPasswordRequest)
}

export function* resetPasswordRequest({
    payload: { setErrors, skipSignIn, ...payload },
}: ReturnType<typeof resetPasswordActions.request>): SagaIterator {
    try {
        yield call(resetPassword, payload)

        yield put(resetPasswordActions.success())
        yield put(removeModal(FORGOT_PASSWORD_MODAL))
        yield put(openForgotPasswordConfirmationModal(payload.email, skipSignIn))
    } catch (e) {
        Sentry.captureException(e)
        setErrors && setErrors(e.result)
        yield put(resetPasswordActions.failure(e.result))
    }
}
