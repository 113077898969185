import { SagaIterator } from "@redux-saga/types"
import { call, put, takeLatest } from "redux-saga/effects"
import { SendConfirmTokenActionTypes } from "../actions/user/types"
import { sendConfirmTokenActions, signUpActions } from "../actions/user/user"
import { sendConfirmToken } from "../../services/user"
import { addModal } from "../actions/ui/ui"
import { openSignInModal } from "../../components/Header/Modals/SignInModal/helpers"
import * as Sentry from "@sentry/node"

export function* watchSendConfirmTokenStart(): SagaIterator {
    yield takeLatest(
        SendConfirmTokenActionTypes.SEND_CONFIRM_TOKEN_REQUEST,
        sendConfirmTokenRequest
    )
}

export function* sendConfirmTokenRequest({
    payload: { router, ...payload },
}: ReturnType<typeof sendConfirmTokenActions.request>) {
    try {
        const { result } = yield call(sendConfirmToken, payload)
        yield put(sendConfirmTokenActions.success(result))
        yield call(router.push, "/")
    } catch (e) {
        Sentry.captureException(e)
        yield put(addModal(openSignInModal))
        yield call(router.push, "/")
        yield put(signUpActions.failure(e))
    }
}
