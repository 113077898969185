import { GetCommentsStatusesActionTypes } from "src/redux/actions/admin/ugc/types"
import { getCommentsStatusesActions } from "src/redux/actions/admin/ugc/ugc"
import { getUgcStatuses } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchGetCommentsStatusesStart(): SagaIterator {
    yield takeEvery(
        GetCommentsStatusesActionTypes.GET_COMMENTS_STATUSES_REQUEST,
        getCommentsStatusesRequest
    )
}

export function* getCommentsStatusesRequest({
    payload: { type },
}: ReturnType<typeof getCommentsStatusesActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getUgcStatuses, { type, access_token })
        yield put(getCommentsStatusesActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getCommentsStatusesActions.failure(e))
    }
}
