import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { LogoutActionType } from "../actions/user/types"
import { call } from "redux-saga/effects"
import { setStore } from "../store"
import { isBrowser } from "../../helpers"
import Router from "next/router"

export function* watchLogout(): SagaIterator {
    yield takeEvery(LogoutActionType.LOGOUT, logoutRequest)
}

export function* logoutRequest(): SagaIterator {
    localStorage.removeItem("persist:user")

    if (Router.route === "/profile" && isBrowser()) {
        yield call(Router.push, "/")
    }

    const { user, ...store } = yield select((store) => store)

    yield call(setStore, store)
}
