import {
    UGCReducerState,
    GetAbuseReportsActionTypes,
    GetAnalysisReportsActionTypes,
    GetAnalysisTopicsActionTypes,
    GetAnalystsActionTypes,
    AssignCommentAnalystActionTypes,
    AssignReportAnalystActionTypes,
    DeleteCommentActionTypes,
    GetCommentsStatusesActionTypes,
    GetAbuseReportsStatusesActionTypes,
    SetCommentStatusActionTypes,
    SetAbuseReportStatusActionTypes,
    PostCommentReplyActionTypes,
    HideReplyActionTypes,
} from "src/redux/actions/admin/ugc/types"
import { Reducer } from "redux"

const initialState: UGCReducerState = {
    analysisReports: {
        page: 1,
        count: 0,
        data: [],
    },
    abuseReports: {
        page: 1,
        count: 0,
        data: [],
    },
    analysisTopics: [],
    commentsStatuses: null,
    abuseReportsStatuses: null,
    analysts: [],
    claims: {
        page: 1,
        count: 0,
        data: [],
    },
    claimsTopics: [],
    loading: false,
    error: null,
}

export const ugc: Reducer<UGCReducerState, any> = (state = initialState, { type, payload }) => {
    switch (type) {
        case GetAbuseReportsActionTypes.GET_ABUSE_REPORTS_REQUEST:
        case GetAnalysisReportsActionTypes.GET_ANALYSIS_REPORTS_REQUEST:
        case GetAnalysisTopicsActionTypes.GET_ANALYSIS_TOPICS_REQUEST:
        case SetCommentStatusActionTypes.SET_COMMENT_STATUS_REQUEST:
        case SetAbuseReportStatusActionTypes.SET_ABUSE_REPORT_STATUS_REQUEST:
        case GetAnalystsActionTypes.GET_ANALYSTS_REQUEST:
        case PostCommentReplyActionTypes.POST_COMMENT_REPLY_REQUEST:
            return { ...state, loading: true }

        case AssignCommentAnalystActionTypes.ASSIGN_COMMENT_ANALYST_REQUEST:
        case AssignReportAnalystActionTypes.ASSIGN_REPORT_ANALYST_REQUEST:
        case DeleteCommentActionTypes.DELETE_COMMENT_REQUEST:
        case GetCommentsStatusesActionTypes.GET_COMMENTS_STATUSES_REQUEST:
        case GetAbuseReportsStatusesActionTypes.GET_ABUSE_REPORTS_STATUSES_REQUEST:
        case HideReplyActionTypes.HIDE_REPLY_REQUEST:
        case AssignCommentAnalystActionTypes.ASSIGN_COMMENT_ANALYST_SUCCESS:
        case AssignReportAnalystActionTypes.ASSIGN_REPORT_ANALYST_SUCCESS:
        case DeleteCommentActionTypes.DELETE_COMMENT_SUCCESS:
        case SetCommentStatusActionTypes.SET_COMMENT_STATUS_SUCCESS:
        case SetAbuseReportStatusActionTypes.SET_ABUSE_REPORT_STATUS_SUCCESS:
        case HideReplyActionTypes.HIDE_REPLY_SUCCESS:
            return { ...state }

        case PostCommentReplyActionTypes.POST_COMMENT_REPLY_SUCCESS:
            return { ...state, loading: false }

        case GetAbuseReportsActionTypes.GET_ABUSE_REPORTS_SUCCESS:
            return { ...state, abuseReports: { ...payload }, loading: false }

        case GetAnalysisReportsActionTypes.GET_ANALYSIS_REPORTS_SUCCESS:
            return { ...state, analysisReports: { ...payload }, loading: false }

        case GetAnalysisTopicsActionTypes.GET_ANALYSIS_TOPICS_SUCCESS:
            return { ...state, analysisTopics: payload }

        case GetAnalystsActionTypes.GET_ANALYSTS_SUCCESS:
            return { ...state, analysts: payload, loading: false }

        case GetCommentsStatusesActionTypes.GET_COMMENTS_STATUSES_SUCCESS:
            return { ...state, commentsStatuses: payload }

        case GetAbuseReportsStatusesActionTypes.GET_ABUSE_REPORTS_STATUSES_SUCCESS:
            return { ...state, abuseReportsStatuses: payload }

        case GetAbuseReportsActionTypes.GET_ABUSE_REPORTS_FAILURE:
        case GetAnalysisReportsActionTypes.GET_ANALYSIS_REPORTS_FAILURE:
        case GetAnalysisTopicsActionTypes.GET_ANALYSIS_TOPICS_FAILURE:
        case GetAnalystsActionTypes.GET_ANALYSTS_FAILURE:
        case AssignCommentAnalystActionTypes.ASSIGN_COMMENT_ANALYST_FAILURE:
        case AssignReportAnalystActionTypes.ASSIGN_REPORT_ANALYST_FAILURE:
        case DeleteCommentActionTypes.DELETE_COMMENT_FAILURE:
        case GetCommentsStatusesActionTypes.GET_COMMENTS_STATUSES_FAILURE:
        case GetAbuseReportsStatusesActionTypes.GET_ABUSE_REPORTS_STATUSES_FAILURE:
        case SetCommentStatusActionTypes.SET_COMMENT_STATUS_FAILURE:
        case SetAbuseReportStatusActionTypes.SET_ABUSE_REPORT_STATUS_FAILURE:
        case PostCommentReplyActionTypes.POST_COMMENT_REPLY_FAILURE:
        case HideReplyActionTypes.HIDE_REPLY_FAILURE:
            return { ...state, loading: false, error: payload }

        default:
            return state
    }
}
