import { EvidenceType, NodeTreeItem } from "src/types"

export type AdminAnalysisReducerState = {
    analysis: EvidenceType | null
    loading: boolean
    analysisList: AnalysisList
    nodeTrees: NodeTreeData[]
    nodeTypes: string[]
    nodeFunctions: string[]
    analysisStatus: AdminItem[] | []
    error?: Array<any>
}

export type AnalysisList = {
    count: number
    page: number | undefined
    data: AnalysisListItem[]
}

export type AnalysisListItem = {
    id: number
    priority?: number
    short_name?: string
    name?: string
    unpublished?: boolean
    created_at?: string
    updated_at?: string
    slug?: string
    calc_version?: string | null
    best_case?: number[][] | null
}

export type UpdateAnalysisRequest = AnalysisListItem & { callback?: any }

export type NodeTreeData = {
    id: number
    data: NodeTreeItem
}

export type AdminItem = {
    email: string
    first_name: string
    id: number
    last_name: string
}

export enum GetAnalysisListActionTypes {
    GET_ANALYSIS_LIST_REQUEST = "@@admin/analysis/GET_ANALYSIS_LIST_REQUEST",
    GET_ANALYSIS_LIST_SUCCESS = "@@admin/analysis/GET_ANALYSIS_LIST_SUCCESS",
    GET_ANALYSIS_LIST_FAILURE = "@@admin/analysis/GET_ANALYSIS_LIST_FAILURE",
}

export enum GetAdminAnalysisActionTypes {
    GET_ADMIN_ANALYSIS_REQUEST = "@@admin/analysis/GET_ADMIN_ANALYSIS_REQUEST",
    GET_ADMIN_ANALYSIS_SUCCESS = "@@admin/analysis/GET_ADMIN_ANALYSIS_SUCCESS",
    GET_ADMIN_ANALYSIS_FAILURE = "@@admin/analysis/GET_ADMIN_ANALYSIS_FAILURE",
}

export enum DeleteAdminAnalysisActionTypes {
    DELETE_ADMIN_ANALYSIS_REQUEST = "@@admin/analysis/DELETE_ADMIN_ANALYSIS_REQUEST",
    DELETE_ADMIN_ANALYSIS_SUCCESS = "@@admin/analysis/DELETE_ADMIN_ANALYSIS_SUCCESS",
    DELETE_ADMIN_ANALYSIS_FAILURE = "@@admin/analysis/DELETE_ADMIN_ANALYSIS_FAILURE",
}

export enum UpdateAnalysisActionTypes {
    UPDATE_ANALYSIS_REQUEST = "@@admin/analysis/UPDATE_ANALYSIS_REQUEST",
    UPDATE_ANALYSIS_SUCCESS = "@@admin/analysis/UPDATE_ANALYSIS_SUCCESS",
    UPDATE_ANALYSIS_FAILURE = "@@admin/analysis/UPDATE_ANALYSIS_FAILURE",
}

export enum GetCalcDetailsActionTypes {
    GET_CALC_DETAILS_REQUEST = "@@admin/analysis/GET_CALC_DETAILS_REQUEST",
    GET_CALC_DETAILS_SUCCESS = "@@admin/analysis/GET_CALC_DETAILS_SUCCESS",
    GET_CALC_DETAILS_FAILURE = "@@admin/analysis/GET_CALC_DETAILS_FAILURE",
}

export enum GetNodeTreeActionTypes {
    GET_NODE_TREE_REQUEST = "@@admin/analysis/GET_NODE_TREE_REQUEST",
    GET_NODE_TREE_SUCCESS = "@@admin/analysis/GET_NODE_TREE_SUCCESS",
    GET_NODE_TREE_FAILURE = "@@admin/analysis/GET_NODE_TREE_FAILURE",
}

export enum GetNodeTypesActionTypes {
    GET_NODE_TYPES_REQUEST = "@@admin/analysis/GET_NODE_TYPES_REQUEST",
    GET_NODE_TYPES_SUCCESS = "@@admin/analysis/GET_NODE_TYPES_SUCCESS",
    GET_NODE_TYPES_FAILURE = "@@admin/analysis/GET_NODE_TYPES_FAILURE",
}

export enum GetNodeFunctionsActionTypes {
    GET_NODE_FUNCTIONS_REQUEST = "@@admin/analysis/GET_NODE_FUNCTIONS_REQUEST",
    GET_NODE_FUNCTIONS_SUCCESS = "@@admin/analysis/GET_NODE_FUNCTIONS_SUCCESS",
    GET_NODE_FUNCTIONS_FAILURE = "@@admin/analysis/GET_NODE_FUNCTIONS_FAILURE",
}

export enum SetAnalysisStatusActionTypes {
    SET_ANALYSIS_STATUS_REQUEST = "@@admin/analysis/SET_ANALYSIS_STATUS_REQUEST",
    SET_ANALYSIS_STATUS_SUCCESS = "@@admin/analysis/SET_ANALYSIS_STATUS_SUCCESS",
    SET_ANALYSIS_STATUS_FAILURE = "@@admin/analysis/SET_ANALYSIS_STATUS_FAILURE",
}

export enum CopyAnalysisActionTypes {
    COPY_ANALYSIS_REQUEST = "@@admin/analysis/COPY_ANALYSIS_REQUEST",
    COPY_ANALYSIS_SUCCESS = "@@admin/analysis/COPY_ANALYSIS_SUCCESS",
    COPY_ANALYSIS_FAILURE = "@@admin/analysis/COPY_ANALYSIS_FAILURE",
}
