import { GetAbuseReportsStatusesActionTypes } from "src/redux/actions/admin/ugc/types"
import { getAbuseReportsStatusesActions } from "src/redux/actions/admin/ugc/ugc"
import { getUgcStatuses } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchGetAbuseReportsStatusesStart(): SagaIterator {
    yield takeEvery(
        GetAbuseReportsStatusesActionTypes.GET_ABUSE_REPORTS_STATUSES_REQUEST,
        getAbuseReportsStatusesRequest
    )
}

export function* getAbuseReportsStatusesRequest({
    payload: { type },
}: ReturnType<typeof getAbuseReportsStatusesActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getUgcStatuses, { type, access_token })
        yield put(getAbuseReportsStatusesActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getAbuseReportsStatusesActions.failure(e))
    }
}
