import { SagaIterator } from "@redux-saga/types"
import { call, put, takeLatest } from "redux-saga/effects"
import { SsoSignOnActionTypes } from "../actions/user/types"
import { ssoSignOnActions } from "../actions/user/user"
import { facebookSignOn, twitterSignOn } from "../../services/user"
import { removeAction, removeAllModal } from "../actions/ui/ui"
import * as Sentry from "@sentry/node"
import { select } from "@redux-saga/core/effects"
import { AppState } from "../reducers"
import { setStore } from "../store"

export function* watchSsoSignOnStart(): SagaIterator {
    yield takeLatest(SsoSignOnActionTypes.SSO_SIGN_ON_REQUEST, ssoSignOnRequest)
}

export function* ssoSignOnRequest({
    payload: { token, type, oauth_token, oauth_verifier },
}: ReturnType<typeof ssoSignOnActions.request>) {
    try {
        if (type === "facebook") {
            const { result } = yield call(facebookSignOn, token)

            yield put(ssoSignOnActions.success(result))

            yield put(removeAllModal())

            const action: () => void = yield select((state: AppState) => state.ui.action)

            if (action) {
                yield call(action)
                yield put(removeAction())
            }

            const store: AppState = yield select((store) => store)
            yield call(setStore, store)
        }

        if (type === "twitter") {
            const { result } = yield call(twitterSignOn, { oauth_token, oauth_verifier })

            window.localStorage.setItem("user", JSON.stringify(result))
            window.close()
        }
    } catch (e) {
        Sentry.captureException(e)
        yield put(ssoSignOnActions.failure(e))
    }
}
