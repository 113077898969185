import { User, UserList } from "src/redux/actions/admin/user/types"
import http from "../http"

export const getUserList = async ({
    access_token,
    page,
    limit,
    id_option,
    id_value,
    email_option,
    email_value,
    name_option,
    name_value,
    link_option,
    link_value,
    sign_in_count_option,
    sign_in_count_value,
    admin_user_email_option,
    admin_user_email_value,
}: {
    access_token: string
    page: number
    limit: number
    id_option?: string
    id_value?: number
    email_option?: string
    email_value?: string
    name_option?: string
    name_value?: string
    link_option?: string
    link_value?: string
    sign_in_count_option?: string
    sign_in_count_value?: number
    admin_user_email_option?: string
    admin_user_email_value?: string
}): Promise<{ result: UserList }> => {
    return await http(`user_admin/list`, {
        method: "GET",
        params: {
            access_token,
            page,
            limit,
            ...(id_option && { id_option }),
            ...(id_value !== undefined && id_value !== null && { id_value }),
            ...(email_option && { email_option }),
            ...(email_value && { email_value }),
            ...(name_option && { name_option }),
            ...(name_value && { name_value }),
            ...(link_option && { link_option }),
            ...(link_value && { link_value }),
            ...(sign_in_count_option && { sign_in_count_option }),
            ...(sign_in_count_value !== undefined &&
                sign_in_count_value !== null && { sign_in_count_value }),
            ...(admin_user_email_option && { admin_user_email_option }),
            ...(admin_user_email_value && { admin_user_email_value }),
        },
    })
}

export const getUserInformation = async ({
    id,
    access_token,
}: {
    id: number | string
    access_token: string
}) => {
    return await http(`user_admin/${id}`, {
        method: "GET",
        params: { access_token },
    })
}

export const deleteUser = async ({
    id,
    access_token,
}: {
    id: number | string
    access_token: string
}) => {
    return await http(`user_admin/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const createUser = async ({ data, access_token }: { data: User; access_token: string }) => {
    return await http("user_admin", {
        method: "POST",
        body: JSON.stringify(data),
        params: { access_token },
        headers: { "Content-Type": "application/json" },
    })
}

export const updateUser = async ({ user, access_token }: { user: User; access_token: string }) => {
    return await http("user_admin", {
        method: "PUT",
        body: JSON.stringify(user),
        params: { access_token },
        headers: { "Content-Type": "application/json" },
    })
}
