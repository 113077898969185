import { GetUserListActionTypes } from "../../actions/admin/user/types"
import { getUserListActions } from "../../actions/admin/user/user"
import { getUserList } from "../../../services/admin/user"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "../../reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchGetUserListStart(): SagaIterator {
    yield takeEvery(GetUserListActionTypes.GET_USER_LIST_REQUEST, getUserListRequest)
}

export function* getUserListRequest({
    payload,
}: ReturnType<typeof getUserListActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const {
            result: { data, count, page },
        } = yield call(getUserList, { ...payload, access_token })
        yield put(getUserListActions.success({ data, count, page }))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getUserListActions.failure(e))
    }
}
