import { Reducer } from "redux"
import {
    HideCommentActionTypes,
    GetAnalysisActionTypes,
    GetCommentsActionTypes,
    GetRepliesActionTypes,
    GetShortAnalysisListActionTypes,
    MainReducerState,
    PostCommentActionTypes,
    PostEditedCommentActionTypes,
    PostReplyActionTypes,
    TopAnalysisActionTypes,
    VoteActionTypes,
    TopUnpublishedAnalysisActionTypes,
    UserEditCommentActionTypes,
    GetSensitivityCalculationActionTypes,
} from "../actions/main/types"

const initialState: MainReducerState = {
    main_page_stories: [],
    main_page_stories_counter: 0,
    mainPageLoader: false,
    tweets: [],
    analise: undefined,
    last_blog_posts: [],
    card_analyses: [],
    comments: [],
    commentsLoader: false,
    commentsCount: 0,
    commentInputLoader: false,
    replies: {},
    topAnalyses: [],
    topAnalysesLoader: false,
    sensitivityCalculation: null,
}

export const mainReducer: Reducer<MainReducerState, any> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case TopAnalysisActionTypes.TOP_ANALYSIS_REQUEST:
        case TopUnpublishedAnalysisActionTypes.TOP_UNPUBLISHED_ANALYSIS_REQUEST:
            return { ...state, topAnalysesLoader: true }

        case GetCommentsActionTypes.GET_COMMENTS_REQUEST:
            return { ...state, commentsLoader: true }

        case PostReplyActionTypes.POST_REPLY_REQUEST:
        case GetRepliesActionTypes.GET_REPLIES_REQUEST:
        case VoteActionTypes.VOTE_REQUEST:
        case PostEditedCommentActionTypes.POST_EDITED_COMMENT_REQUEST:
        case UserEditCommentActionTypes.USER_EDIT_COMMENT_REQUEST:
        case HideCommentActionTypes.HIDE_COMMENT_REQUEST:
            return { ...state }

        case GetAnalysisActionTypes.GET_ANALYSIS_REQUEST:
        case GetSensitivityCalculationActionTypes.GET_SENSITIVITY_CALCULATION_REQUEST:
            return { ...state, mainPageLoader: true }

        case GetShortAnalysisListActionTypes.GET_SHORT_ANALYSIS_REQUEST:
            return { ...state, mainPageLoader: true }

        case PostCommentActionTypes.POST_COMMENT_REQUEST:
            return { ...state, commentInputLoader: true }

        case TopAnalysisActionTypes.TOP_ANALYSIS_SUCCESS:
        case TopUnpublishedAnalysisActionTypes.TOP_UNPUBLISHED_ANALYSIS_SUCCESS:
            return { ...state, topAnalyses: payload, topAnalysesLoader: false }

        case GetCommentsActionTypes.GET_COMMENTS_SUCCESS:
            return {
                ...state,
                comments: [...state.comments, ...payload.comments],
                commentsCount: payload.comments_count,
                replies: { ...state.replies, ...payload.replies },
                commentsLoader: false,
            }

        case PostCommentActionTypes.POST_COMMENT_SUCCESS:
            return {
                ...state,
                comments: [...payload.comments],
                commentInputLoader: false,
                commentsCount: payload.comments_count,
            }

        case PostReplyActionTypes.POST_REPLY_SUCCESS:
            return { ...state, replies: payload.replies, comments: [...payload.comments] }

        case GetRepliesActionTypes.GET_REPLIES_SUCCESS:
            return { ...state, replies: payload }

        case VoteActionTypes.VOTE_SUCCESS:
        case PostEditedCommentActionTypes.POST_EDITED_COMMENT_SUCCESS:
        case UserEditCommentActionTypes.USER_EDIT_COMMENT_SUCCESS:
        case HideCommentActionTypes.HIDE_COMMENT_SUCCESS:
            return { ...state, ...payload }

        case GetAnalysisActionTypes.GET_ANALYSIS_SUCCESS:
            return { ...state, mainPageLoader: false, ...payload }

        case GetSensitivityCalculationActionTypes.GET_SENSITIVITY_CALCULATION_SUCCESS:
            return { ...state, mainPageLoader: false, sensitivityCalculation: payload }

        case GetShortAnalysisListActionTypes.GET_SHORT_ANALYSIS_SUCCESS:
            return {
                ...state,
                main_page_stories: [
                    ...(state.main_page_stories || []),
                    ...payload.main_page_stories,
                ],
                main_page_stories_counter: payload.main_page_stories_counter,
                mainPageLoader: false,
            }

        case TopAnalysisActionTypes.TOP_ANALYSIS_FAILURE:
        case TopUnpublishedAnalysisActionTypes.TOP_UNPUBLISHED_ANALYSIS_FAILURE:
            return { ...state, topAnalysesLoader: false }

        case GetCommentsActionTypes.GET_COMMENTS_FAILURE:
            return { ...state, commentsLoader: false }

        case PostCommentActionTypes.POST_COMMENT_FAILURE:
            return { ...state, commentInputLoader: false }

        case GetShortAnalysisListActionTypes.GET_SHORT_ANALYSIS_FAILURE:
        case GetSensitivityCalculationActionTypes.GET_SENSITIVITY_CALCULATION_FAILURE:
            return { ...state, mainPageLoader: false }

        case PostReplyActionTypes.POST_REPLY_FAILURE:
        case GetRepliesActionTypes.GET_REPLIES_FAILURE:
        case VoteActionTypes.VOTE_FAILURE:
        case PostEditedCommentActionTypes.POST_EDITED_COMMENT_FAILURE:
        case UserEditCommentActionTypes.USER_EDIT_COMMENT_FAILURE:
        case HideCommentActionTypes.HIDE_COMMENT_FAILURE:
        case GetAnalysisActionTypes.GET_ANALYSIS_FAILURE:
            return { ...state }

        default:
            return state
    }
}
