import { SetAbuseReportStatusActionTypes } from "src/redux/actions/admin/ugc/types"
import {
    getAbuseReportsActions,
    setAbuseReportStatusActions,
} from "src/redux/actions/admin/ugc/ugc"
import { getAbuseReports, setAbuseReportStatus } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"
import { message } from "antd"

const call: any = Effects.call

export function* watchSetAbuseReportStatusRequestStart(): SagaIterator {
    yield takeEvery(
        SetAbuseReportStatusActionTypes.SET_ABUSE_REPORT_STATUS_REQUEST,
        setAbuseReportStatusRequest
    )
}

export function* setAbuseReportStatusRequest({
    payload: {
        status,
        id,
        pageNumber,
        limit,
        searchField,
        text,
        order,
        orderField,
        analyst,
        filterStatus,
    },
}: ReturnType<typeof setAbuseReportStatusActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        yield call(setAbuseReportStatus, { status, id, access_token })
        yield put(setAbuseReportStatusActions.success())

        const {
            result: { data, count, page },
        } = yield call(getAbuseReports, {
            page: pageNumber,
            limit,
            searchField,
            text,
            order,
            orderField,
            analyst,
            status: filterStatus,
            access_token,
        })
        yield put(getAbuseReportsActions.success({ data, count, page }))

        message.success("Status has been changed")
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(setAbuseReportStatusActions.failure(e))
    }
}
