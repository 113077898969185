import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { put } from "redux-saga/effects"
import * as Effects from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import { GetSensitivityCalculationActionTypes } from "../actions/main/types"
import { getSensitivityCalculationActions } from "../actions/main/main"
import { getSensitivityCalculation } from "src/services"

const call: any = Effects.call

export function* getSensitivityCalc(): SagaIterator {
    yield takeEvery(
        GetSensitivityCalculationActionTypes.GET_SENSITIVITY_CALCULATION_REQUEST,
        getSensitivityCalculationRequest
    )
}

export function* getSensitivityCalculationRequest({
    payload,
}: ReturnType<typeof getSensitivityCalculationActions.request>): SagaIterator {
    try {
        const { result } = yield call(getSensitivityCalculation, { id: payload.id })

        yield put(getSensitivityCalculationActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getSensitivityCalculationActions.failure(e))
    }
}
