import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetAnalysisActionTypes } from "../actions/main/types"
import { call, put } from "redux-saga/effects"
import { getComments } from "../../services/main"
import { GetAnalysisActions } from "../actions/main/main"
import * as Sentry from "@sentry/node"
import { getAnalyse } from "../../services"
import { AppState } from "../reducers"
import Router from "next/router"
import { addTargetBlankToStoryData } from "src/helpers"

export function* getAnalysis(): SagaIterator {
    yield takeEvery(GetAnalysisActionTypes.GET_ANALYSIS_REQUEST, getAnalysisRequest)
}

export function* getAnalysisRequest({
    payload,
}: ReturnType<typeof GetAnalysisActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)

        const { result: analise } = yield call(getAnalyse, { slug: payload.slug, access_token })

        if (analise.slug && payload.slug !== analise.slug) {
            yield call(Router.push, analise.slug)
        }

        if (analise.slug === null) {
            yield call(Router.push, analise.url)
        }

        const {
            result: { comments, comments_count: commentsCount, replies },
        } = yield call(getComments, payload)

        yield put(
            GetAnalysisActions.success({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                analise: addTargetBlankToStoryData(analise),
                comments,
                commentsCount,
                replies,
            })
        )
    } catch (e) {
        Sentry.captureException(e)
        yield put(GetAnalysisActions.failure(e))
        yield call(Router.push, "/404")
    }
}
