import { GetAbuseReportsActionTypes } from "src/redux/actions/admin/ugc/types"
import { getAbuseReportsActions } from "src/redux/actions/admin/ugc/ugc"
import { getAbuseReports } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchGetAbuseReportsStart(): SagaIterator {
    yield takeEvery(GetAbuseReportsActionTypes.GET_ABUSE_REPORTS_REQUEST, getAbuseReportsRequest)
}

export function* getAbuseReportsRequest({
    payload,
}: ReturnType<typeof getAbuseReportsActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const {
            result: { data, count, page },
        } = yield call(getAbuseReports, { ...payload, access_token })
        yield put(getAbuseReportsActions.success({ data, count, page }))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getAbuseReportsActions.failure(e))
    }
}
