import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetCalcDetailsActionTypes } from "src/redux/actions/admin/analysis/types"
import { getCalcDetailsActions } from "src/redux/actions/admin/analysis/analysis"
import { call, put } from "redux-saga/effects"
import { getCalcDetails } from "src/services/admin/analysis"
import * as Sentry from "@sentry/node"
import { AppState } from "src/redux/reducers"

export function* watchGetCalcDetailsStart(): SagaIterator {
    yield takeEvery(GetCalcDetailsActionTypes.GET_CALC_DETAILS_REQUEST, getCalcDetailsRequest)
}

export function* getCalcDetailsRequest({
    payload,
}: ReturnType<typeof getCalcDetailsActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getCalcDetails, { ...payload, access_token })

        yield put(getCalcDetailsActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getCalcDetailsActions.failure(e))
    }
}
