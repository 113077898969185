import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { PostCommentReplyActionTypes } from "src/redux/actions/admin/ugc/types"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import { postCommentReply } from "src/services/admin/ugc"
import { postCommentReplyActions } from "src/redux/actions/admin/ugc/ugc"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* postCommentReplyStart(): SagaIterator {
    yield takeEvery(PostCommentReplyActionTypes.POST_COMMENT_REPLY_REQUEST, postCommentReplyRequest)
}

export function* postCommentReplyRequest({
    payload: { commentId, replyText, action },
}: ReturnType<typeof postCommentReplyActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(postCommentReply, { commentId, text: replyText, access_token })
        if (action) {
            action()
        }
        yield put(postCommentReplyActions.success())
        message.success("Reply has been added")
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(postCommentReplyActions.failure(e))
    }
}
