export type UsersReducerState = {
    access_token: string
    email: string
    id: number | null
    list: UserList
    loading: boolean
    userView: UserItemView | null
}

export type UserListItem = {
    id: number
    email: string
    name: string
    sign_in_count: number
    confirmed_at: Date
    current_sign_in_at: Date
    last_sign_in_at: Date
    notify_on_rc: boolean
    notify_on_email: boolean
    admin_user_email: string
}

export type UserList = {
    page: number | undefined
    count: number
    data: UserListItem[]
}

export type UserItemView = {
    id: number
    email: string
    encrypted_password: string
    reset_password_token: string | null
    reset_password_sent_at: Date | null
    remember_created_at: Date | null
    sign_in_count: number | null
    current_sign_in_at: Date | null
    last_sign_in_at: Date | null
    current_sign_in_ip: string
    last_sign_in_ip: string | null
    name: string | null
    confirmation_token: string | null
    confirmed_at: Date | null
    confirmation_sent_at: Date | null
    unconfirmed_email: string | null
    image: string | null
    link: string | null
    original_url: string | null
    cloud_img_pub_id: string | null
    notify_on_rc: boolean
    notify_on_email: boolean
    admin_user_email: string
}

export type User = {
    id?: number
    email: string
    name: string | null
    password: string
    image: string | null
    link: string | null
    original_url: string
    notify_on_rc: boolean
    notify_on_email: boolean
    admin_user_email: string | null
}

export enum GetUserListActionTypes {
    GET_USER_LIST_REQUEST = "@@admin/admin/GET_USER_LIST_REQUEST",
    GET_USER_LIST_SUCCESS = "@@admin/admin/GET_USER_LIST_SUCCESS",
    GET_USER_LIST_FAILURE = "@@admin/admin/GET_USER_LIST_FAILURE",
}

export enum GetUserInfoActionTypes {
    GET_USER_INFO_REQUEST = "@@admin/admin/GET_USER_INFO_REQUEST",
    GET_USER_INFO_SUCCESS = "@@admin/admin/GET_USER_INFO_SUCCESS",
    GET_USER_INFO_FAILURE = "@@admin/admin/GET_USER_INFO_FAILURE",
}

export enum DeleteUserActionTypes {
    DELETE_USER_REQUEST = "@@admin/admin/DELETE_USER_REQUEST",
    DELETE_USER_SUCCESS = "@@admin/admin/DELETE_USER_SUCCESS",
    DELETE_USER_FAILURE = "@@admin/admin/DELETE_USER_FAILURE",
}
