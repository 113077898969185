import { select, takeEvery } from "@redux-saga/core/effects"
import { getSubEvidenceActions } from "../actions/main/main"
import { call } from "redux-saga/effects"
import { getSubEvidence } from "../../services"
import { AppState } from "../reducers"
import { EvidenceType } from "../../types"
import * as Sentry from "@sentry/node"

const getEvidences = (appState: AppState) => appState.main.analise?._evidence

export function* watchSubEvidence() {
    yield takeEvery(getSubEvidenceActions.request, subEvidenceRequest)
}

export function* subEvidenceRequest({
    payload: { id, setSubEvidence },
}: ReturnType<typeof getSubEvidenceActions.request>) {
    try {
        const selectedSubEvidence: EvidenceType[] = yield select(getEvidences)
        const foundEvidence = selectedSubEvidence.find((evidence) => evidence.id == id)

        if (foundEvidence?._evidence) {
            setSubEvidence(foundEvidence._evidence)
            return
        }

        const { result } = yield call(getSubEvidence, id)

        setSubEvidence(result)
    } catch (e) {
        Sentry.captureException(e)
    }
}
