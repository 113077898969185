import { addModal } from "../../../../redux/actions/ui/ui"
import { FORGOT_PASSWORD_CONFIRMATION } from "../../../../constants/modals"
import ForgotPasswordConfirmationModal from "./index"

export const openForgotPasswordConfirmationModal = (email: string, skipSignIn?: boolean) =>
    addModal({
        id: FORGOT_PASSWORD_CONFIRMATION,
        hideCross: true,
        children: <ForgotPasswordConfirmationModal email={email} skipSignIn={skipSignIn} />,
        size: "auto",
    })
