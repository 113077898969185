import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AssignReportAnalystActionTypes } from "src/redux/actions/admin/ugc/types"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import { assignReportAnalyst } from "src/services/admin/ugc"
import { assignReportAnalystActions } from "src/redux/actions/admin/ugc/ugc"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* assignReportAnalystStart(): SagaIterator {
    yield takeEvery(
        AssignReportAnalystActionTypes.ASSIGN_REPORT_ANALYST_REQUEST,
        assignReportAnalystRequest
    )
}

export function* assignReportAnalystRequest({
    payload: { abuse_id, analyst_id },
}: ReturnType<typeof assignReportAnalystActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(assignReportAnalyst, { abuse_id, analyst_id, access_token })

        yield put(assignReportAnalystActions.success())

        message.success("Analyst has been assigned")
    } catch (e) {
        message.error("Something went wrong")
        Sentry.captureException(e)
        yield put(assignReportAnalystActions.failure(e))
    }
}
