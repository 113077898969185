import http from "./http"
import {
    Analise,
    AnalysisUrls,
    BlogPost,
    CardAnalysis,
    Claim,
    EvidenceType,
    Story,
    Tweet,
} from "../types"
import { ShortAnalysisListParams } from "../redux/actions/main/types"
import { InfoPageReducerState } from "../redux/actions/admin/infoPage/types"

export const getShortAnalysisList = async (
    params: ShortAnalysisListParams & { access_token: string }
): Promise<{ result: { main_page_stories: Story[]; main_page_stories_counter: number } }> => {
    return await http("analysis/short", { method: "GET", params })
}
export const getShortAnalysisListPublic = async (
    params: ShortAnalysisListParams
): Promise<{ result: { main_page_stories: Story[]; main_page_stories_counter: number } }> => {
    return await http("analysis/public-list", { method: "GET", params })
}

export const getAnalyse = async ({
    slug,
    access_token,
}: {
    slug?: string
    access_token: string
}): Promise<{ result: Analise }> => {
    return await http(`analysis/${slug}`, { method: "GET", params: { access_token } })
}

export const getPublicAnalyse = async ({
    slug,
}: {
    slug: string
}): Promise<{ result: Analise; statusCode: number }> => {
    const response: { result: number } = await http(`analysis/get-id/${slug}`, {
        method: "GET",
    })

    return await http(`analysis/public/byId/${response.result}`, { method: "GET" })
}

export const getAnalysesList = async (): Promise<{ result: AnalysisUrls[] }> => {
    return await http(`analysis/list`, { method: "GET" })
}

export const getClaim = async ({ slug }: { slug: string }): Promise<{ result: Claim }> => {
    return await http(`analysis/claims/${slug}`, { method: "GET" })
}

export const getSubEvidence = async (id: number): Promise<{ result: EvidenceType[] }> => {
    return await http(`analysis/subEvidence/${id}`, { method: "GET" })
}

export const getBlogPosts = async (): Promise<{ result: BlogPost[] }> => {
    return await http("news/posts", {
        method: "GET",
    })
}
export const getLastTweets = async (): Promise<{ result: Tweet[] }> => {
    try {
        return await http("news/tweets", {
            method: "GET",
        })
    } catch (e) {
        return { result: [] }
    }
}

export const getPublicInfoPage = async (url: string): Promise<{ result: InfoPageReducerState }> => {
    return await http(`info_pages/${url}`, {
        method: "GET",
    })
}

const mapAnalysisAsV1 = (cardAnalysis: CardAnalysis) => {
    return {
        ...cardAnalysis,
        comments_count: cardAnalysis.commentsCount,
        question: cardAnalysis.title,
        scenarios: (cardAnalysis.hypotheses || []).map((hypothesis, index) => ({
            id: index,
            inProgr: false,
            text: hypothesis.summary,
            net_prob:
                hypothesis.effect && hypothesis.effect[0]
                    ? hypothesis.effect[0].weigthedValue * 100
                    : 0,
            name: hypothesis.title,
            nickname: hypothesis.title,
            short_name: hypothesis.title,
        })),
        slug: `${cardAnalysis.id}`,
        created_at: cardAnalysis.publishedAt,
        updated_at: cardAnalysis.updatedAt,
        isclaim: false,
        url: `https://cards.rootclaim.com/analysis/${cardAnalysis.id}`,
        cover_photo: {
            image_url: cardAnalysis.cover || "",
            alt_text: cardAnalysis.title,
        },
        isCardAnalysis: true,
    }
}

export const getCardAnalyses = async (): Promise<{ result: CardAnalysis[] }> => {
    try {
        const result: CardAnalysis[] = await http(
            `https://rootclaim-back-v2-live-863cd3d7054f.herokuapp.com/analysis`,
            {
                method: "GET",
                rawUrl: true,
                withoutToken: true,
            }
        )

        return { result: result.map((analysis) => mapAnalysisAsV1(analysis)) }
    } catch (e) {
        return { result: [] }
    }
}

export const getSensitivityCalculation = async ({
    id,
}: {
    id: string | number
}): Promise<{ result: any }> => {
    return await http(`analysis_admin/newCalc/${id}`, {
        method: "GET",
    })
}

export const getPublicSensitivityCalculation = async ({
    id,
}: {
    id: string | number
}): Promise<{ result: any }> => {
    return await http(`analysis_admin/newCalcPublish/${id}`, {
        method: "GET",
    })
}
