import { SUCCESS_UNSUBSCRIBE_MODAL } from "src/constants/modals"
import SuccessUnsubscribeModal from "./SuccessUnsubscribeModal"

// types
import { ModalPropsType } from "src/redux/actions/ui/types"

export const openSuccessUnsubscribeModal: ModalPropsType = {
    id: SUCCESS_UNSUBSCRIBE_MODAL,
    size: "auto",
    children: <SuccessUnsubscribeModal />,
    hideCross: true,
}
