import { SagaIterator } from "@redux-saga/types"
import { put, takeEvery } from "@redux-saga/core/effects"
import { SetNewPasswordActionTypes } from "../actions/user/types"
import { setNewPasswordActions } from "../actions/user/user"
import { call } from "redux-saga/effects"
import { setNewPassword } from "../../services/user"
import { addModal, removeModal } from "../actions/ui/ui"
import { SET_NEW_PASSWORD_MODAL } from "../../constants/modals"
import { openSignInModal } from "../../components/Header/Modals/SignInModal/helpers"
import * as Sentry from "@sentry/node"

export function* watchSetNewPasswordStart(): SagaIterator {
    yield takeEvery(SetNewPasswordActionTypes.SET_NEW_PASSWORD_REQUEST, setNewPasswordRequest)
}

export function* setNewPasswordRequest({
    payload: { setErrors, ...payload },
}: ReturnType<typeof setNewPasswordActions.request>): SagaIterator {
    try {
        yield call(setNewPassword, payload)

        yield put(setNewPasswordActions.success())
        yield put(removeModal(SET_NEW_PASSWORD_MODAL))
        yield put(addModal(openSignInModal))
    } catch (e) {
        Sentry.captureException(e)
        setErrors(e.result)
        yield put(setNewPasswordActions.failure(e.result))
    }
}
