import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { UpdateAvatarActionTypes } from "../actions/user/types"
import { updateAvatarActions } from "../actions/user/user"
import { call, put } from "redux-saga/effects"
import { updateAvatar } from "../../services/user"
import * as Sentry from "@sentry/node"

export function* watchUpdateAvatar(): SagaIterator {
    yield takeEvery(UpdateAvatarActionTypes.UPDATE_AVATAR_REQUEST, updateAvatarRequest)
}

export function* updateAvatarRequest(
    payload: ReturnType<typeof updateAvatarActions.request>
): SagaIterator {
    try {
        const { result } = yield call(updateAvatar, payload)

        yield put(updateAvatarActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(updateAvatarActions.failure(e))
    }
}
