import { SetCommentStatusActionTypes } from "src/redux/actions/admin/ugc/types"
import { setCommentStatusActions } from "src/redux/actions/admin/ugc/ugc"
import { setCommentStatus } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"
import { message } from "antd"

const call: any = Effects.call

export function* watchSetCommentStatusRequestStart(): SagaIterator {
    yield takeEvery(SetCommentStatusActionTypes.SET_COMMENT_STATUS_REQUEST, setCommentStatusRequest)
}

export function* setCommentStatusRequest({
    payload: { status, id },
}: ReturnType<typeof setCommentStatusActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        yield call(setCommentStatus, { status, id, access_token })
        yield put(setCommentStatusActions.success())
        message.success("Status has been changed")
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(setCommentStatusActions.failure(e))
    }
}
