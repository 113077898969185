import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { UpdatePasswordActionTypes } from "../actions/user/types"
import { updatePasswordActions } from "../actions/user/user"
import { call, put } from "redux-saga/effects"
import { updatePassword } from "../../services/user"
import * as Sentry from "@sentry/node"

export function* watchUpdatePassword(): SagaIterator {
    yield takeEvery(UpdatePasswordActionTypes.UPDATE_PASSWORD_REQUEST, updatePasswordRequest)
}

export function* updatePasswordRequest(
    payload: ReturnType<typeof updatePasswordActions.request>
): SagaIterator {
    try {
        const {
            payload: { setEditPassword, resetForm },
        } = payload
        yield call(updatePassword, payload)
        yield put(updatePasswordActions.success(null))
        resetForm()
        setEditPassword(false)
    } catch (e) {
        Sentry.captureException(e)
        payload.payload.setErrors(e.result)
        yield put(updatePasswordActions.failure(e))
    }
}
