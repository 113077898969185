import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { call, put } from "redux-saga/effects"

// utils
import { GetNodeTreeActionTypes } from "src/redux/actions/admin/analysis/types"
import { getNodeTreeActions } from "src/redux/actions/admin/analysis/analysis"
import { getNodeTree } from "src/services/admin/analysis"

// ui
import { message } from "antd"

export function* watchGetNodeTree(): SagaIterator {
    yield takeEvery(GetNodeTreeActionTypes.GET_NODE_TREE_REQUEST, getNodeTreeRequest)
}

export function* getNodeTreeRequest({
    payload: { id, callback, callback2 },
}: ReturnType<typeof getNodeTreeActions.request>): SagaIterator {
    try {
        const { result } = yield call(getNodeTree, { id })

        if (callback) {
            callback(result)
        }

        if (callback2) {
            callback2()
        }

        yield put(getNodeTreeActions.success({ id, data: result }))

        if (result.message) {
            message.error(`${result.message}`)
        }
    } catch (e) {
        yield put(getNodeTreeActions.failure(e.message))
    }
}
