import { Field, Form, Formik, FormikHelpers } from "formik"
import { useDispatch } from "react-redux"
import { forgotPasswordConfirmationSchema } from "./ForgotPasswordConfirmationModal.schema"
import { resetPasswordConfirmationActions } from "../../../../redux/actions/user/user"
import { StyledBodyMain, StyledSubText } from "../../../../styles/Text/Text.styles"
import { StyledForgotPasswordConfirmationModal } from "./ForgotPasswordConfirmationModal.styles"
import Input from "../../../../reusableComponents/Formik/Input"
import CustomErrorMessage from "../../../../reusableComponents/CustomErrorMessage"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { addModal, removeModal } from "../../../../redux/actions/ui/ui"
import { FORGOT_PASSWORD_CONFIRMATION, SIGN_IN_MODAL } from "../../../../constants/modals"
import SignInModal from "../SignInModal"
import { useResendModal } from "../../../../hooks/useResendEmail"

type Props = {
    email: string
    skipSignIn?: boolean
}

type FormValues = {
    code: string
}

const ForgotPasswordConfirmationModal = ({ email, skipSignIn }: Props) => {
    const dispatch = useDispatch()
    const [openResendModal] = useResendModal(email)
    const onSubmit = (value: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        dispatch(resetPasswordConfirmationActions.request({ ...value, email, setErrors }))
    }
    const onCancel = () => {
        dispatch(removeModal(FORGOT_PASSWORD_CONFIRMATION))
        !skipSignIn &&
            dispatch(addModal({ id: SIGN_IN_MODAL, size: "auto", children: <SignInModal /> }))
    }

    return (
        <StyledForgotPasswordConfirmationModal>
            <div className="marginBottom">
                <StyledBodyMain weight={600}>Enter security code.</StyledBodyMain>
                <StyledSubText color="grey" className="stringRow">
                    Please check your email for a message with your code. Your code is 6 numbers
                    long.
                </StyledSubText>
            </div>
            <Formik
                initialValues={{ code: "" }}
                validationSchema={forgotPasswordConfirmationSchema}
                onSubmit={onSubmit}
            >
                {({ dirty }) => (
                    <Form>
                        <div className="marginBottom">
                            <div className="row">
                                <Field type="code" name="code" as={Input} placeholder="Code" />
                                <div className="email">
                                    <StyledBodyMain>We sent your code to: </StyledBodyMain>
                                    <StyledBodyMain weight={600}>{email}</StyledBodyMain>
                                </div>
                            </div>
                            <CustomErrorMessage name="code" component="div" />
                        </div>
                        <StyledSubText
                            color="blue"
                            weight={600}
                            inline
                            className="link"
                            onClick={openResendModal}
                        >
                            Didn't get a code?
                        </StyledSubText>
                        <div className="buttonsBlock">
                            <ButtonOutlined onClick={onCancel} block>
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary disabled={!dirty} type="submit" block>
                                Submit
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledForgotPasswordConfirmationModal>
    )
}

export default ForgotPasswordConfirmationModal
