import {
    CreateInfoPageRequest,
    InfoPageReducerState,
    InfoPageType,
    InfoPageList,
} from "../../redux/actions/admin/infoPage/types"
import http from "../http"

export const getInfoPageById = async ({
    id,
    access_token,
}: {
    id: string | number
    access_token: string
}): Promise<{ result: InfoPageReducerState }> => {
    return await http(`info_pages_admin/${id}`, {
        method: "GET",
        params: { access_token },
    })
}

export const getInfoPageByUrl = async ({
    url,
    access_token,
}: {
    url: string
    access_token: string
}): Promise<{ result: InfoPageReducerState }> => {
    return await http(`info_pages_admin/view/${url}`, {
        method: "GET",
        params: { access_token },
    })
}

export const updateInfoPage = async ({
    access_token,
    ...payload
}: InfoPageType & { id: number; access_token: string }) => {
    return await http("info_pages_admin", {
        method: "PATCH",
        body: JSON.stringify(payload),
        params: { access_token },
        headers: { "Content-Type": "application/json" },
    })
}

export const publishInfoPage = async ({
    id,
    access_token,
}: {
    id: number | string
    access_token: string
}) => {
    return await http(`info_pages_admin/${id}`, {
        method: "POST",
        params: { access_token },
    })
}

export const createInfoPage = async ({
    payload,
    access_token,
}: {
    payload: CreateInfoPageRequest
    access_token: string
}) => {
    return await http("info_pages_admin", {
        method: "POST",
        body: JSON.stringify(payload),
        params: { access_token },
        headers: { "Content-Type": "application/json" },
    })
}

export const getInfoPageList = async ({
    access_token,
    page,
    limit,
}: {
    access_token: string
    page: number
    limit: number | string
}): Promise<{ result: InfoPageList }> => {
    return await http(`info_pages_admin`, {
        method: "GET",
        params: { access_token, page, limit },
    })
}

export const deleteInfoPage = async ({
    access_token,
    ...payload
}: InfoPageType & { id: number; access_token: string }) => {
    return await http("info_pages_admin", {
        method: "DELETE",
        body: JSON.stringify(payload),
        params: { access_token },
        headers: { "Content-Type": "application/json" },
    })
}
