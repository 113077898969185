import http from "./http"
import {
    LoginRequest,
    ResetPasswordConfirmCodeRequest,
    ResetPasswordRequest,
    SendConfirmCodeRequest,
    SendConfirmTokenRequest,
    SetNewPasswordRequest,
    SignUpRequest,
    UserResponse,
    UnsubscribeRequest,
} from "../redux/actions/user/types"
import {
    resetPasswordConfirmationActions,
    shareByEmailActions,
    updateAvatarActions,
    updatePasswordActions,
    updateUserNameActions,
} from "../redux/actions/user/user"

export const signUp = async (payload: SignUpRequest): Promise<{ result: UserResponse }> => {
    return await http("auth", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const facebookSignOn = (accessToken?: string) =>
    http("auth/facebook", {
        method: "GET",
        params: { access_token: accessToken },
    })

export const twitterSignOn = ({
    oauth_token,
    oauth_verifier,
}: {
    oauth_token?: string | string[]
    oauth_verifier?: string | string[]
}) =>
    http("auth/twitter", {
        method: "GET",
        params: { oauth_token, oauth_verifier },
    })

export const sendConfirmCode = async (
    payload: SendConfirmCodeRequest
): Promise<{ result: boolean }> => {
    return await http("auth/confirmation", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const sendConfirmToken = async (
    payload: SendConfirmTokenRequest
): Promise<{ result: boolean }> => {
    return await http("auth/confirmation", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const unsubscribe = async (payload: UnsubscribeRequest): Promise<{ result: boolean }> => {
    return await http("user/unsubscribe", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const login = async (payload: LoginRequest): Promise<{ result: UserResponse }> => {
    return await http("auth/login", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const loginAdmin = async (payload: LoginRequest): Promise<{ result: UserResponse }> => {
    return await http("auth/login/adminUser", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const resetPassword = async (payload: ResetPasswordRequest): Promise<any> => {
    return await http("auth/resetPassword", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const resetPasswordConfirmCode = async (
    payload: ResetPasswordConfirmCodeRequest
): Promise<{ result: string }> => {
    return await http("auth/confirmResetPassword", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const setNewPassword = async (payload: SetNewPasswordRequest): Promise<any> => {
    return await http("auth/resetPassword", {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const resendConfirmEmail = async ({
    payload,
}: ReturnType<typeof resetPasswordConfirmationActions.request>): Promise<void> => {
    return await http("auth/resendConfirmEmail", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}

export const updateName = async ({
    payload: { name },
}: ReturnType<typeof updateUserNameActions.request>): Promise<any> => {
    return await http("user/name", {
        method: "PUT",
        body: JSON.stringify({ name }),
        headers: { "Content-Type": "application/json" },
    })
}

export const updatePassword = async ({
    payload: { oldPassword, password },
}: ReturnType<typeof updatePasswordActions.request>): Promise<any> => {
    return await http("user/password", {
        method: "PUT",
        body: JSON.stringify({ oldPassword, password }),
        headers: { "Content-Type": "application/json" },
    })
}

export const updateAvatar = async ({
    payload: { image },
}: ReturnType<typeof updateAvatarActions.request>): Promise<{ result: { image: string } }> => {
    const formData = new FormData()
    formData.append("image", image)
    return await http("user/avatar", {
        method: "PUT",
        body: formData,
    })
}

export const shareByEmail = async ({
    payload,
}: ReturnType<typeof shareByEmailActions.request>): Promise<any> => {
    return await http("user/shareByEmail", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
    })
}
