import { get } from "lodash"
import * as Sentry from "@sentry/node"
import { getToken } from "pages/_app"

interface Options {
    [key: string]: any
}

const getResponseMessage = (message: string) => {
    return (message && message !== "" && message) || ""
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = async <T>(response: Response, method: string): Promise<T> => {
    const json = await response.json()
    if (response.status >= 200 && response.status < 300) {
        const message = getResponseMessage(json.message)
        message && method !== "GET" && console.info(message)

        return json
    }
    if (
        response.status === 500 ||
        response.status === 422 ||
        response.status === 412 ||
        response.status === 404 ||
        response.status === 400
    ) {
        const message = getResponseMessage(json.message)
        Sentry.captureException(message)
        console.error("request error", message)
    }

    if (response.status === 404 && method === "GET") {
        const message = getResponseMessage(json.message)
        Sentry.captureException(message)
        console.error("request error", message)
    }

    // catches error in sagas
    throw json
}

/**
 * @param url string
 * @param options object
 * @returns {Promise<T>}
 */
const http = async <T>(url: string, options: Options): Promise<T> => {
    const newOptions: Options = { ...options }

    if (!options.withoutToken) {
        newOptions.headers = {
            Authorization: "Bearer " + (options?.params?.access_token || getToken()),
        }
    }

    if (options.headers && !options.withoutContentType) {
        newOptions.headers = {
            ...newOptions.headers,
            ...options.headers,
        }
    }
    if (options.headers && options.withoutContentType) {
        newOptions.headers = {
            ...newOptions.headers,
            ...options.headers,
        }
        delete newOptions.headers["Content-Type"]
    }

    if (options.params?.access_token) {
        delete newOptions.params.access_token
    }

    const urlParams = new URLSearchParams(get(options, "params", {}))
    const newUrl = options.rawUrl
        ? url
        : `${process.env.NEXT_PUBLIC_APP_API_URL}${url}${
              urlParams.toString() ? "?" : ""
          }${urlParams.toString()}`
    const response = await fetch(encodeURI(newUrl), newOptions)

    const { method } = newOptions
    return checkStatus(response, method)
}

export default http
