import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { GetRepliesActionTypes } from "../actions/main/types"
import { call, put } from "redux-saga/effects"
import { getReplies } from "../../services/main"
import { getRepliesActions } from "../actions/main/main"
import { AppState } from "../reducers"
import * as Sentry from "@sentry/node"

const selectReplies = (store: AppState) => store.main.replies || {}

export function* watchGetReplies(): SagaIterator {
    yield takeEvery(GetRepliesActionTypes.GET_REPLIES_REQUEST, getRepliesRequest)
}

export function* getRepliesRequest({
    payload: { id, setLoading, limit, offset },
}: ReturnType<typeof getRepliesActions.request>): SagaIterator {
    try {
        setLoading(true)

        const { result } = yield call(getReplies, { id, limit, offset })

        const replies = yield select(selectReplies)

        replies[id] = replies[id] ? [...replies[id], ...result] : [...result]

        yield put(getRepliesActions.success({ ...replies }))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getRepliesActions.failure(e))
    } finally {
        setLoading(false)
    }
}
