import http from "../http"
import {
    AbuseReportsList,
    AnalysisReportsList,
    AnalysisTopicsList,
    AnalystsList,
    ClaimsList,
    ClaimsTopicsList,
} from "src/redux/actions/admin/ugc/types"

export const getAbuseReports = async ({
    access_token,
    page,
    limit,
    searchField,
    text,
    order,
    orderField,
    analyst,
    status,
}: {
    access_token: string
    page: number
    limit: number | string
    searchField?: string
    text?: string
    order?: "ASC" | "DESC"
    orderField?: string
    analyst?: number
    status?: string
}): Promise<{ result: AbuseReportsList }> => {
    return await http(`ugc/abuse-reports`, {
        method: "GET",
        params: {
            access_token,
            page,
            limit,
            ...(searchField && { searchField }),
            ...(text && { text }),
            ...(order && { order }),
            ...(orderField && { orderField }),
            ...(analyst && { analyst }),
            ...(status && { status }),
        },
    })
}

export const getAnalysisReports = async ({
    access_token,
    page,
    limit,
    searchField,
    text,
    order,
    orderField,
    analyst,
    status,
}: {
    access_token: string
    page: number
    limit: number | string
    searchField?: string
    text?: string
    order?: "ASC" | "DESC"
    orderField?: string
    analyst?: number
    status?: string
}): Promise<{ result: AnalysisReportsList }> => {
    return await http(`ugc/analysis`, {
        method: "GET",
        params: {
            access_token,
            page,
            limit,
            ...(searchField && { searchField }),
            ...(text && { text }),
            ...(order && { order }),
            ...(orderField && { orderField }),
            ...(analyst && { analyst }),
            ...(status && { status }),
        },
    })
}

export const getAnalysisTopics = async ({
    access_token,
}: {
    access_token: string
}): Promise<{ result: AnalysisTopicsList }> => {
    return await http(`ugc/topics/analysis`, {
        method: "GET",
        params: { access_token },
    })
}

export const getAnalysts = async ({
    access_token,
}: {
    access_token: string
}): Promise<{ result: AnalystsList }> => {
    return await http(`ugc/get-analists`, {
        method: "GET",
        params: { access_token },
    })
}

// TODO: - check endpoint url and types
export const getClaims = async ({
    access_token,
    page,
    limit,
    searchField,
    text,
    order,
    orderField,
}: {
    access_token: string
    page: number
    limit: number | string
    searchField?: string
    text?: string
    order?: "ASC" | "DESC"
    orderField?: string
}): Promise<{ result: ClaimsList }> => {
    return await http(`ugc/claims`, {
        method: "GET",
        params: {
            access_token,
            page,
            limit,
            ...(searchField && { searchField }),
            ...(text && { text }),
            ...(order && { order }),
            ...(orderField && { orderField }),
        },
    })
}

// TODO: - check endpoint url and types
export const getClaimsTopics = async ({
    access_token,
}: {
    access_token: string
}): Promise<{ result: ClaimsTopicsList }> => {
    return await http(`ugc/topics/claims`, {
        method: "GET",
        params: { access_token },
    })
}

export const restoreComment = async ({
    id,
    type,
    access_token,
}: {
    id: number
    type: string
    access_token: string
}) => {
    return await http(`comments/adminUser/restore/${id}`, {
        method: "PUT",
        body: JSON.stringify({ type, id }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const assignCommentAnalyst = async ({
    ugc_id,
    analyst_id,
    access_token,
}: {
    ugc_id: number
    analyst_id: number
    access_token: string
}) => {
    return await http(`ugc/set-comment-analyst`, {
        method: "PUT",
        body: JSON.stringify({ ugc_id, analyst_id }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const assignReportAnalyst = async ({
    abuse_id,
    analyst_id,
    access_token,
}: {
    abuse_id: number
    analyst_id: number
    access_token: string
}) => {
    return await http(`ugc/set-abuse-analyst`, {
        method: "PUT",
        body: JSON.stringify({ abuse_id, analyst_id }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const deleteComment = async ({
    id,
    type,
    access_token,
}: {
    id: number
    type: string
    access_token: string
}) => {
    return await http(`comments/delete-forever`, {
        method: "DELETE",
        body: JSON.stringify({ type, id }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const getUgcStatuses = async ({
    type,
    access_token,
}: {
    type: "abuse" | "ugc"
    access_token: string
}) => {
    return await http(`ugc/get-statuses/${type}`, {
        method: "GET",
        params: { access_token },
    })
}

export const setCommentStatus = async ({
    status,
    id,
    access_token,
}: {
    status: string
    id: number
    access_token: string
}) => {
    return await http("ugc/set-ugc-status", {
        method: "PATCH",
        body: JSON.stringify({ status, id }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const setAbuseReportStatus = async ({
    status,
    id,
    access_token,
}: {
    status: string
    id: number
    access_token: string
}) => {
    return await http("ugc/set-abuse-report-status", {
        method: "PATCH",
        body: JSON.stringify({ status, id }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const postCommentReply = async ({
    commentId,
    text,
    access_token,
}: {
    commentId: number
    text: string
    access_token: string
}) => {
    return await http(`replies/admin/${commentId}`, {
        method: "POST",
        body: JSON.stringify({ text }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}
