import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { UpdateInfoPageActionTypes } from "../../actions/admin/infoPage/types"
import { updateInfoPageActions } from "../../actions/admin/infoPage/infoPage"
import { call, put } from "redux-saga/effects"
import { updateInfoPage } from "../../../services/admin/infoPage"
import { message } from "antd"
import { AppState } from "../../reducers"

export function* watchUpdateInfoPageStart(): SagaIterator {
    yield takeEvery(UpdateInfoPageActionTypes.UPDATE_INFO_PAGE_REQUEST, updateInfoPageRequest)
}

export function* updateInfoPageRequest({
    payload,
}: ReturnType<typeof updateInfoPageActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { id } = yield select((state: AppState) => state.infoPageReducer.adminInfoPage)
        const { result } = yield call(updateInfoPage, { id, ...payload, access_token })

        yield put(updateInfoPageActions.success(result))

        if (payload.action) {
            yield call(payload.action)
        }

        message.success("Changes successfully saved")
    } catch (e) {
        yield put(updateInfoPageActions.failure(e.result))
        message.error(e.message)
    }
}
