import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { HideCommentActionTypes, Replies } from "../actions/main/types"
import { hideCommentActions } from "../actions/main/main"
import { call, put } from "redux-saga/effects"
import { hideComment } from "../../services/main"
import {
    getCommentsCountSelector,
    getCommentsSelector,
    getRepliesSelector,
} from "../../helpers/selectors"
import { Comment } from "../../types"
import * as Sentry from "@sentry/node"
import { removeModal } from "../actions/ui/ui"
import { CONFIRM_DELETE_MODAL } from "../../constants/modals"
import { AppState } from "../reducers"

export function* watchHideComment(): SagaIterator {
    yield takeEvery(HideCommentActionTypes.HIDE_COMMENT_REQUEST, hideCommentRequest)
}

export function* hideCommentRequest({
    payload,
}: ReturnType<typeof hideCommentActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        yield call(hideComment, { ...payload, access_token })

        const replies: Replies = yield select(getRepliesSelector)
        const comments: Comment[] = yield select(getCommentsSelector)
        let commentsCount: number = yield select(getCommentsCountSelector)

        const filterFunction = (comment: Comment) => comment.id !== payload.id

        let mappedComments = comments
        if (payload.type === "comment") {
            mappedComments = comments.filter(filterFunction)
            commentsCount--
        }

        let mappedReplies: Replies = replies
        if (payload.type === "reply") {
            mappedReplies = {}
            Object.entries(replies).forEach(([key, comments]) => {
                mappedReplies[+key] = comments.filter(filterFunction)
            })

            mappedComments = mappedComments.map(({ replies_count = 0, ...comment }) => {
                return { ...comment, replies_count: --replies_count }
            })
        }

        yield put(
            hideCommentActions.success({
                comments: mappedComments,
                replies: mappedReplies,
                commentsCount,
            })
        )

        yield put(removeModal(CONFIRM_DELETE_MODAL))
    } catch (e) {
        Sentry.captureException(e)
        yield put(hideCommentActions.failure(e))
    }
}
