import { SET_NEW_PASSWORD_MODAL } from "../../../../constants/modals"
import SetNewPasswordModal from "./SetNewPasswordModal"
import { ModalPropsType } from "../../../../redux/actions/ui/types"

export const openSetNewPasswordModal = (reset_password_token: string): ModalPropsType => ({
    id: SET_NEW_PASSWORD_MODAL,
    children: <SetNewPasswordModal reset_password_token={reset_password_token} />,
    title: "",
    hideCross: true,
    size: "auto",
})
