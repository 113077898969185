import styled from "styled-components"

export const StyledModal = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: 575px) {
        min-width: 400px;
    }

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    p {
        margin: 0 0 10px;
    }
`
