import { createGlobalStyle } from "styled-components"
import { colors } from "../constants/colors"

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserat', sans-serif;
        font-size: 18px;
        font-style: normal;
    }
    
    a {
        color: ${colors.darkBlue};
        text-decoration: none;
    }
    
    p {
        margin-block-start: 16px;
        margin-block-end: 16px;
    }
    
    img {
        max-width: 100%;
    }

    body > div {
        background: ${colors.background};
    }
    
    .desktopHide {
        display: none !important;
    }
    
    .loaderWrapper {
        position: relative;
    }
    
    .noWrap {
        white-space: nowrap;
    }
    
    .overflowAuto {
        overflow: auto;
    }
    
    :focus-visible, :focus, :active, :hover {
        outline: none;
    }

    .ant-table-tbody > tr > td {
        padding: 10px 16px;
    }

    .ant-table-thead > tr > th {
        font-weight: 700;
        padding: 12px 16px;
    }
    
    .balloon-row {
        display: flex;
        flex-wrap: wrap;
    }

    span[style="font-family: Montserat, sans-serif;"],
    span[style="font-family: Montserat;"] {
        * {
            font-family: Montserat, sans-serif !important;
        }
    }
    
    .pre-line * {
        white-space: pre-line
    }

    .d-flex {
        display: flex;
    }
    
    .d-block {
        display: block;
    }

    .items-center {
        align-items: center;
    }

    .justify-between {
        justify-content: space-between;
    }

    .flex-row {
        flex-direction: row;
    }

    .m-0 {
        margin: 0;
    }
    
    .overflow-auto {
        overflow: auto;
    }
    
    .width-unset {
        max-width: unset !important;
        min-width: unset !important;
        width: auto !important;
    }
    
    .text-center {
        text-align: center;
    }
    
    .font-bold {
        font-weight: 700;
    }
    
    .width-50 {
        width: 50%; 
    }

    @media (max-width: 767px) {
        body {
            font-size: 18px;
        }
        
        .desktopHide {
            display: inline-flex !important;
        }
        
        .mobileHide {
            display: none !important;
        }
    }



 
`
