import http from "../http"
import { AnalysisListItem, AnalysisList } from "src/redux/actions/admin/analysis/types"
import {
    DefinitionItem,
    EmbedSources,
    EvidenceSource,
    EvidenceType,
    UpdateDefinitionItem,
    UpdateEvidenceOrderType,
    UpdateEvidenceSource,
    UpdateEvidenceType,
    UpdateHypothesis,
    UpdateSorting,
    UploadImageType,
    AddNodeCalcRequestTypes,
    AddNewNodeRequestTypes,
    UpdateNodeRequestTypes,
    AddNodeSourceRequestTypes,
    UpdateNodeSourceRequestTypes,
    AddHypothesisResponseTypes,
} from "../../types"

export const getAnalysisList = async ({
    access_token,
    page,
    limit,
}: {
    access_token: string
    page: number
    limit: number | string
}): Promise<{ result: AnalysisList }> => {
    return await http(`analysis_admin`, {
        method: "GET",
        params: { access_token, page, limit },
    })
}

export const getAdminAnalysis = async ({
    access_token,
    slug,
}: {
    slug: string | string[]
    access_token: string
}): Promise<{ result: AnalysisListItem[] }> => {
    return await http(`analysis_admin/${slug}`, { method: "GET", params: { access_token } })
}

export const createAnalysis = async ({
    access_token,
    name,
}: {
    name: string
    access_token: string
}): Promise<{ result: { id: number; slug: string } }> => {
    return await http(`analysis_admin`, {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({ name }),
        headers: { "Content-Type": "application/json" },
    })
}

export const deleteAnalysis = async ({
    access_token,
    id,
}: {
    id: number
    access_token: string
}): Promise<{ result: { id: number; slug: string } }> => {
    return await http(`analysis_admin/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const publishAnalysis = async ({
    access_token,
    id,
}: {
    id: number
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/${id}`, {
        method: "POST",
        params: { access_token },
    })
}

export const addHypothesis = async ({
    access_token,
    inference_object_id,
    is_rejected,
}: {
    inference_object_id: number
    access_token: string
    is_rejected?: boolean
}): Promise<{
    result: AddHypothesisResponseTypes
}> => {
    return await http(`analysis_admin/hypotheses/${inference_object_id}`, {
        method: "POST",
        body: JSON.stringify({ is_rejected }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const deleteHypothesis = async ({
    access_token,
    id,
    is_rejected,
}: {
    id: number
    access_token: string
    is_rejected?: boolean
}): Promise<{
    result: { id: number; best_case: number[][] }[]
}> => {
    return await http(`analysis_admin/hypotheses/${id}`, {
        method: "DELETE",
        body: JSON.stringify({ is_rejected }),
        headers: { "Content-Type": "application/json" },
        params: { access_token },
    })
}

export const updateHypothesis = async ({
    access_token,
    is_rejected,
    ...hypothesis
}: UpdateHypothesis & {
    access_token: string
    is_rejected?: boolean
}): Promise<void> => {
    return await http(`analysis_admin/hypotheses`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({ ...hypothesis, is_rejected: is_rejected }),
        headers: { "Content-Type": "application/json" },
    })
}

export const addEvidence = async ({
    access_token,
    inference_object_id,
    order_index,
    sub_evidence,
}: {
    inference_object_id: number
    access_token: string
    order_index: number
    sub_evidence?: boolean
}): Promise<{
    result: EvidenceType
}> => {
    return await http(`analysis_admin/evidence/${inference_object_id}`, {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({ order_index, sub_evidence }),
        headers: { "Content-Type": "application/json" },
    })
}

export const deleteEvidence = async ({
    access_token,
    id,
}: {
    id: number
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/evidence/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const updateEvidence = async ({
    access_token,
    ...evidence
}: UpdateEvidenceType & {
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/evidence`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify(evidence),
        headers: { "Content-Type": "application/json" },
    })
}

export const updateEvidenceOrder = async ({
    access_token,
    evidenceOrder,
}: {
    evidenceOrder: UpdateEvidenceOrderType[]
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/evidence/order`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({ evidenceOrder }),
        headers: { "Content-Type": "application/json" },
    })
}

export const updateAnalysis = async ({
    access_token,
    ...analysis
}: UpdateEvidenceType & {
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify(analysis),
        headers: { "Content-Type": "application/json" },
    })
}

export const getCalcDetails = async ({
    access_token,
    id,
}: {
    access_token: string
    id: number
}): Promise<void> => {
    return await http(`analysis_admin/updateCalcDetails/${id}`, {
        method: "GET",
        params: { access_token },
    })
}

export const updatePriority = async ({
    access_token,
    ...analysis
}: UpdateSorting & {
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/sorting/`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify(analysis),
        headers: { "Content-Type": "application/json" },
    })
}

export const deleteImage = async ({
    access_token,
    imageId,
}: {
    imageId: number
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/image/${imageId}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const saveImage = async ({
    access_token,
    ...uploadImage
}: UploadImageType & {
    access_token: string
}): Promise<{ result: EmbedSources }> => {
    return await http(`analysis_admin/image`, {
        method: "PUT",
        params: { access_token },
        body: JSON.stringify(uploadImage),
        headers: { "Content-Type": "application/json" },
    })
}

export const addSource = async ({
    access_token,
    inference_object_id,
    hypothesis_id,
}: {
    hypothesis_id?: number
    inference_object_id?: number
    access_token: string
}): Promise<{
    result: EvidenceSource
}> => {
    return await http(`analysis_admin/link/`, {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({ inference_object_id, hypothesis_id }),
        headers: { "Content-Type": "application/json" },
    })
}

export const updateSource = async ({
    access_token,
    ...source
}: UpdateEvidenceSource & {
    access_token: string
}): Promise<{
    result: EvidenceSource
}> => {
    return await http(`analysis_admin/link/`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify(source),
        headers: { "Content-Type": "application/json" },
    })
}

export const deleteSource = async ({
    access_token,
    id,
}: {
    id: number
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/link/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const addDefinition = async ({
    access_token,
    id,
}: {
    id: number
    access_token: string
}): Promise<{
    result: DefinitionItem
}> => {
    return await http(`analysis_admin/definition/${id}`, {
        method: "POST",
        params: { access_token },
    })
}

export const updateDefinition = async ({
    access_token,
    ...definition
}: UpdateDefinitionItem & {
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/definition/`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify(definition),
        headers: { "Content-Type": "application/json" },
    })
}

export const deleteDefinition = async ({
    access_token,
    id,
}: {
    id: number
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/definition/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const addNodeCalculation = async ({
    access_token,
    title,
    text,
    input_value,
    hypothesis_id,
    type,
    function_name,
}: AddNodeCalcRequestTypes & {
    access_token: string
}): Promise<void> => {
    return await http(`nodesCalc/add-new-calculation`, {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({
            title,
            text,
            input_value,
            hypothesis_id,
            type,
            function_name,
        }),
        headers: { "Content-Type": "application/json" },
    })
}

export const addNewNode = async ({
    access_token,
    parent,
    title,
    text,
    type,
    input_value,
    function_name,
}: AddNewNodeRequestTypes & {
    access_token: string
}): Promise<void> => {
    return await http(`nodesCalc/add-new-node`, {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({
            parent,
            title,
            text,
            type,
            input_value,
            function_name,
        }),
        headers: { "Content-Type": "application/json" },
    })
}

export const updateNode = async ({
    access_token,
    id,
    title,
    text,
    input_value,
    ignore_input_value,
    function_name,
    type,
}: UpdateNodeRequestTypes & {
    access_token: string
}): Promise<void> => {
    return await http(`nodesCalc/update-node`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({
            id,
            input_value,
            ...(title && { title }),
            ...(text && { text }),
            ...(function_name && { function_name }),
            ...(type && { type }),
            ignore_input_value,
        }),
        headers: { "Content-Type": "application/json" },
    })
}

export const getNodeTree = async ({ id }: { id: number }): Promise<void> => {
    return await http(`nodesCalc/get-tree/${id}`, {
        method: "GET",
    })
}

export const getNodeTypes = async (): Promise<void> => {
    return await http(`nodesCalc/get-node-types`, {
        method: "GET",
    })
}

export const getNodeFunctions = async (): Promise<void> => {
    return await http(`nodesCalc/get-functions-list`, {
        method: "GET",
    })
}

export const deleteNode = async ({
    access_token,
    id,
}: {
    id: number
    access_token: string
}): Promise<void> => {
    return await http(`nodesCalc/delete-node/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const addNodeSource = async ({
    access_token,
    node_id,
    link,
    reference,
    quote,
    name,
}: AddNodeSourceRequestTypes & {
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/link`, {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({
            node_id,
            link,
            reference,
            quote,
            name,
        }),
        headers: { "Content-Type": "application/json" },
    })
}

export const updateNodeSource = async ({
    access_token,
    id,
    name,
    link,
    reference,
    quote,
}: UpdateNodeSourceRequestTypes & {
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/link`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({
            id,
            ...(name && { name }),
            ...(link && { link }),
            ...(reference && { reference }),
            ...(quote && { quote }),
        }),
        headers: { "Content-Type": "application/json" },
    })
}

export const deleteNodeSource = async ({
    access_token,
    id,
}: {
    id: number | undefined
    access_token: string
}): Promise<void> => {
    return await http(`analysis_admin/link/${id}`, {
        method: "DELETE",
        params: { access_token },
    })
}

export const setGeneralRatio = async ({
    access_token,
    id,
    parentId,
}: {
    id: number
    parentId: number
    access_token: string
}): Promise<void> => {
    return await http(`nodesCalc/set-general-ratio`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({
            id,
            parentId,
        }),
        headers: { "Content-Type": "application/json" },
    })
}

export const getHypothesisNodesList = async ({
    access_token,
    hypothesisId,
}: {
    hypothesisId: number
    access_token: string
}): Promise<{ result: { id: number; title: string }[] }> => {
    return await http(`nodesCalc/get-list/${hypothesisId}`, {
        method: "GET",
        params: { access_token },
    })
}

export const moveNode = async ({
    access_token,
    id,
    newParentId,
}: {
    id: number | undefined
    newParentId: number
    access_token: string
}): Promise<void> => {
    return await http(`nodesCalc/move-sub-tree/`, {
        method: "PATCH",
        params: { access_token },
        body: JSON.stringify({
            id,
            newParentId,
        }),
        headers: { "Content-Type": "application/json" },
    })
}

export const setEntityStatus = async ({
    access_token,
    entity_id,
    entity,
}: {
    access_token: string
    entity_id: number
    entity: string
}): Promise<void> => {
    return await http("admin/activity", {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({ entity_id, entity }),
        headers: { "Content-Type": "application/json" },
    })
}

export const copyAnalysis = async ({
    analysisId,
    access_token,
}: {
    analysisId: number
    access_token: string
}): Promise<void> => {
    return await http("analysis_admin/copy", {
        method: "POST",
        params: { access_token },
        body: JSON.stringify({ analysisId }),
        headers: { "Content-Type": "application/json" },
    })
}
