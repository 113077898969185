import { createAsyncAction } from "typesafe-actions"
import {
    GetAbuseReportsActionTypes,
    GetAnalysisReportsActionTypes,
    GetAnalysisTopicsActionTypes,
    GetAnalystsActionTypes,
    GetClaimsActionTypes,
    AbuseReportsList,
    AnalysisReportsList,
    AnalysisTopicsList,
    AnalystsList,
    ClaimsTopicsList,
    GetClaimsTopicsActionTypes,
    ClaimsList,
    RestoreCommentActionTypes,
    AssignCommentAnalystActionTypes,
    AssignReportAnalystActionTypes,
    DeleteCommentActionTypes,
    EditCommentActionTypes,
    GetCommentsStatusesActionTypes,
    GetAbuseReportsStatusesActionTypes,
    SetCommentStatusActionTypes,
    SetAbuseReportStatusActionTypes,
    PostCommentReplyActionTypes,
    HideReplyActionTypes,
} from "./types"

export const getAbuseReportsActions = createAsyncAction(
    GetAbuseReportsActionTypes.GET_ABUSE_REPORTS_REQUEST,
    GetAbuseReportsActionTypes.GET_ABUSE_REPORTS_SUCCESS,
    GetAbuseReportsActionTypes.GET_ABUSE_REPORTS_FAILURE
)<
    {
        limit: number
        page: number
        searchField?: string
        text?: string | number
        order?: string
        orderField?: string
        analyst?: number
        status?: string
    },
    AbuseReportsList,
    { error: any }
>()

export const getAnalysisReportsActions = createAsyncAction(
    GetAnalysisReportsActionTypes.GET_ANALYSIS_REPORTS_REQUEST,
    GetAnalysisReportsActionTypes.GET_ANALYSIS_REPORTS_SUCCESS,
    GetAnalysisReportsActionTypes.GET_ANALYSIS_REPORTS_FAILURE
)<
    {
        limit: number
        page: number
        searchField?: string
        text?: string | number
        order?: string
        orderField?: string
        analyst?: number
        status?: string
    },
    AnalysisReportsList,
    { error: any }
>()

export const getAnalysisTopicsActionTypes = createAsyncAction(
    GetAnalysisTopicsActionTypes.GET_ANALYSIS_TOPICS_REQUEST,
    GetAnalysisTopicsActionTypes.GET_ANALYSIS_TOPICS_SUCCESS,
    GetAnalysisTopicsActionTypes.GET_ANALYSIS_TOPICS_FAILURE
)<undefined, AnalysisTopicsList, { error: any }>()

export const getAnalystsActionTypes = createAsyncAction(
    GetAnalystsActionTypes.GET_ANALYSTS_REQUEST,
    GetAnalystsActionTypes.GET_ANALYSTS_SUCCESS,
    GetAnalystsActionTypes.GET_ANALYSTS_FAILURE
)<undefined, AnalystsList, { error: any }>()

export const getClaimsActions = createAsyncAction(
    GetClaimsActionTypes.GET_CLAIMS_REQUEST,
    GetClaimsActionTypes.GET_CLAIMS_SUCCESS,
    GetClaimsActionTypes.GET_CLAIMS_FAILURE
)<
    {
        limit: number
        page: number
        searchField?: string
        text?: string | number
        order?: string
        orderField?: string
    },
    ClaimsList,
    { error: any }
>()

export const getClaimsTopicsActions = createAsyncAction(
    GetClaimsTopicsActionTypes.GET_CLAIMS_TOPICS_REQUEST,
    GetClaimsTopicsActionTypes.GET_CLAIMS_TOPICS_SUCCESS,
    GetClaimsTopicsActionTypes.GET_CLAIMS_TOPICS_FAILURE
)<undefined, ClaimsTopicsList, { error: any }>()

export const restoreCommentActions = createAsyncAction(
    RestoreCommentActionTypes.RESTORE_COMMENT_REQUEST,
    RestoreCommentActionTypes.RESTORE_COMMENT_SUCCESS,
    RestoreCommentActionTypes.RESTORE_COMMENT_FAILURE
)<{ id: number; type: string; action: any }, undefined, { error: any }>()

export const assignCommentAnalystActions = createAsyncAction(
    AssignCommentAnalystActionTypes.ASSIGN_COMMENT_ANALYST_REQUEST,
    AssignCommentAnalystActionTypes.ASSIGN_COMMENT_ANALYST_SUCCESS,
    AssignCommentAnalystActionTypes.ASSIGN_COMMENT_ANALYST_FAILURE
)<{ ugc_id: number; analyst_id: number }, undefined, { error: any }>()

export const assignReportAnalystActions = createAsyncAction(
    AssignReportAnalystActionTypes.ASSIGN_REPORT_ANALYST_REQUEST,
    AssignReportAnalystActionTypes.ASSIGN_REPORT_ANALYST_SUCCESS,
    AssignReportAnalystActionTypes.ASSIGN_REPORT_ANALYST_FAILURE
)<{ abuse_id: number; analyst_id: number }, undefined, { error: any }>()

export const deleteCommentActions = createAsyncAction(
    DeleteCommentActionTypes.DELETE_COMMENT_REQUEST,
    DeleteCommentActionTypes.DELETE_COMMENT_SUCCESS,
    DeleteCommentActionTypes.DELETE_COMMENT_FAILURE
)<{ id: number; type: string; action?: any }, undefined, { error: any }>()

export const editCommentActions = createAsyncAction(
    EditCommentActionTypes.EDIT_COMMENT_REQUEST,
    EditCommentActionTypes.EDIT_COMMENT_SUCCESS,
    EditCommentActionTypes.EDIT_COMMENT_FAILURE
)<
    {
        body: { text: string; commentId: number }
        type: "comment" | "reply"
    },
    undefined,
    { error: any }
>()

export const getCommentsStatusesActions = createAsyncAction(
    GetCommentsStatusesActionTypes.GET_COMMENTS_STATUSES_REQUEST,
    GetCommentsStatusesActionTypes.GET_COMMENTS_STATUSES_SUCCESS,
    GetCommentsStatusesActionTypes.GET_COMMENTS_STATUSES_FAILURE
)<{ type: "ugc" }, string[], { error: any }>()

export const getAbuseReportsStatusesActions = createAsyncAction(
    GetAbuseReportsStatusesActionTypes.GET_ABUSE_REPORTS_STATUSES_REQUEST,
    GetAbuseReportsStatusesActionTypes.GET_ABUSE_REPORTS_STATUSES_SUCCESS,
    GetAbuseReportsStatusesActionTypes.GET_ABUSE_REPORTS_STATUSES_FAILURE
)<{ type: "abuse" }, string[], { error: any }>()

export const setCommentStatusActions = createAsyncAction(
    SetCommentStatusActionTypes.SET_COMMENT_STATUS_REQUEST,
    SetCommentStatusActionTypes.SET_COMMENT_STATUS_SUCCESS,
    SetCommentStatusActionTypes.SET_COMMENT_STATUS_FAILURE
)<{ status: string; id: number }, undefined, { error: any }>()

export const setAbuseReportStatusActions = createAsyncAction(
    SetAbuseReportStatusActionTypes.SET_ABUSE_REPORT_STATUS_REQUEST,
    SetAbuseReportStatusActionTypes.SET_ABUSE_REPORT_STATUS_SUCCESS,
    SetAbuseReportStatusActionTypes.SET_ABUSE_REPORT_STATUS_FAILURE
)<
    {
        status: string
        id: number
        pageNumber: number
        limit: number | undefined
        searchField?: string
        text?: string
        order?: string
        orderField?: string
        analyst?: number | undefined
        filterStatus?: string
    },
    undefined,
    { error: any }
>()

export const postCommentReplyActions = createAsyncAction(
    PostCommentReplyActionTypes.POST_COMMENT_REPLY_REQUEST,
    PostCommentReplyActionTypes.POST_COMMENT_REPLY_SUCCESS,
    PostCommentReplyActionTypes.POST_COMMENT_REPLY_FAILURE
)<
    {
        commentId: number
        replyText: string
        action: any
    },
    undefined,
    { error: any }
>()

export const hideReplyActions = createAsyncAction(
    HideReplyActionTypes.HIDE_REPLY_REQUEST,
    HideReplyActionTypes.HIDE_REPLY_SUCCESS,
    HideReplyActionTypes.HIDE_REPLY_FAILURE
)<{ id: number; type: string; commentId?: number; action: any }, undefined, { error: any }>()
