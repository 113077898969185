import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { GetCommentsActionTypes } from "../actions/main/types"
import { put } from "redux-saga/effects"
import { getComments } from "../../services/main"
import { getCommentsActions } from "../actions/main/main"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchGetComments(): SagaIterator {
    yield takeEvery(GetCommentsActionTypes.GET_COMMENTS_REQUEST, getCommentsRequest)
}

export function* getCommentsRequest({
    payload,
}: ReturnType<typeof getCommentsActions.request>): SagaIterator {
    try {
        const { result } = yield call(getComments, payload)
        yield put(getCommentsActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getCommentsActions.failure(e))
    }
}
