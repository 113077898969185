import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { CreateInfoPageActionTypes } from "../../actions/admin/infoPage/types"
import { createInfoPageActions } from "../../actions/admin/infoPage/infoPage"
import { call } from "redux-saga/effects"
import { createInfoPage } from "../../../services/admin/infoPage"
import { message } from "antd"
import router from "next/router"
import { AppState } from "../../reducers"

export function* watchCreateInfoPageStart(): SagaIterator {
    yield takeEvery(CreateInfoPageActionTypes.CREATE_INFO_PAGE_REQUEST, updateInfoPageRequest)
}

export function* updateInfoPageRequest({
    payload,
}: ReturnType<typeof createInfoPageActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)

        const {
            result: { id },
        } = yield call(createInfoPage, { payload, access_token })

        message.success("New page successfully created")
        yield call(router.push, `infoPages/${id}`)
    } catch (e) {
        message.error(e.message)
    }
}
