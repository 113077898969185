import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { call, put } from "redux-saga/effects"

// utils
import { GetNodeTreeActionTypes } from "src/redux/actions/admin/analysis/types"
import { getNodeTypesActions } from "src/redux/actions/admin/analysis/analysis"
import { getNodeTypes } from "src/services/admin/analysis"

// ui
import { message } from "antd"

export function* watchGetNodeTypes(): SagaIterator {
    yield takeEvery(GetNodeTreeActionTypes.GET_NODE_TREE_REQUEST, getNodeTypesRequest)
}

export function* getNodeTypesRequest(): SagaIterator {
    try {
        const { result } = yield call(getNodeTypes)
        yield put(getNodeTypesActions.success(result))
    } catch (e) {
        yield put(getNodeTypesActions.failure(e.message))
        message.error(e.message)
    }
}
