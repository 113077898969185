import { SagaIterator } from "@redux-saga/types"
import { call, put, takeLatest } from "redux-saga/effects"
import { SignUpActionTypes } from "../actions/user/types"
import { signUpActions } from "../actions/user/user"
import { signUp } from "../../services/user"
import { addModal, removeAction, removeModal } from "../actions/ui/ui"
import { SIGN_UP_MODAL } from "../../constants/modals"
import { addEmailModal } from "../../components/Header/Modals/EmailSentModal/helpers"
import * as Sentry from "@sentry/node"
import { AppState } from "../reducers"
import { select } from "@redux-saga/core/effects"

export function* watchSignUpStart(): SagaIterator {
    yield takeLatest(SignUpActionTypes.SIGN_UP_REQUEST, signUpRequest)
}

export function* signUpRequest({
    payload: { setErrors, ...payload },
}: ReturnType<typeof signUpActions.request>) {
    try {
        const { result } = yield call(signUp, payload)

        const action: () => void = yield select((state: AppState) => state.ui.action)

        yield put(signUpActions.success(result))

        yield put(removeModal(SIGN_UP_MODAL))

        if (action) {
            yield call(action)
            yield put(removeAction())
        }

        if (payload.guestMode) {
            return
        }
        yield put(addModal(addEmailModal(payload.email)))
    } catch (e) {
        Sentry.captureException(e)
        setErrors(e.result)
        yield put(signUpActions.failure(e))
    }
}
