import { SagaIterator } from "@redux-saga/types"
import { call, put, takeLatest } from "redux-saga/effects"
import { UnsubscribeActionTypes } from "../actions/user/types"
import { unsubscribeActions } from "../actions/user/user"
import { unsubscribe } from "../../services/user"
import { addModal } from "../actions/ui/ui"
import { openSuccessUnsubscribeModal } from "src/components/Header/Modals/SuccessUnsubscribeModal/helpers"
import * as Sentry from "@sentry/node"

export function* watchUnsubscribeStart(): SagaIterator {
    yield takeLatest(UnsubscribeActionTypes.UNSUBSCRIBE_REQUEST, unsubscribeRequest)
}

export function* unsubscribeRequest({
    payload: { router, ...payload },
}: ReturnType<typeof unsubscribeActions.request>) {
    try {
        yield call(unsubscribe, payload)
        yield put(addModal(openSuccessUnsubscribeModal))
        yield put(unsubscribeActions.success())
        yield call(router.push, "/")
    } catch (e) {
        Sentry.captureException(e)
        yield call(router.push, "/")
        yield put(unsubscribeActions.failure(e))
    }
}
