import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { HideReplyActionTypes } from "src/redux/actions/admin/ugc/types"
import { hideReplyActions } from "src/redux/actions/admin/ugc/ugc"
import { call, put } from "redux-saga/effects"
import { hideComment } from "src/services/main"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import { AppState } from "../../reducers"

export function* watchHideReply(): SagaIterator {
    yield takeEvery(HideReplyActionTypes.HIDE_REPLY_REQUEST, hideReplyRequest)
}

export function* hideReplyRequest({
    payload: { id, type, action },
}: ReturnType<typeof hideReplyActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        yield call(hideComment, { id, type, access_token })
        if (action) {
            action()
        }
        yield put(hideReplyActions.success())
        message.success("Reply has been hidden")
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(hideReplyActions.failure(e))
    }
}
