import { all, fork } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { watchLoginStart } from "./login"
import { watchSignUpStart } from "./signUp"
import { watchSendConfirmCodeStart } from "./sendConfirmCode"
import { watchResetPasswordConfirmationStart } from "./resetPasswordConfirmation"
import { watchResetPasswordStart } from "./resetPassword"
import { watchSetNewPasswordStart } from "./setNewPassword"
import { watchSendConfirmTokenStart } from "./sendConfirmToken"
import { watchTopAnalysis } from "./getTopAnalysis"
import { watchTopUnpublishedAnalysis } from "./getTopUnpublishedAnalysis"
import { watchGetComments } from "./getComments"
import { watchPostComment } from "./postComment"
import { watchPostReply } from "./postReply"
import { watchGetReplies } from "./getReplies"
import { watchVote } from "./vote"
import { watchPostReport } from "./postReport"
import { watchSubEvidence } from "./getSubEvidence"
import { watchResendConfirmEmail } from "./resendConfirmEmail"
import { getShortAnalysis } from "./getShortAnalysis"
import { watchUpdateUserName } from "./updateUserName"
import { watchUpdatePassword } from "./updatePassword"
import { watchUpdateAvatar } from "./updateAvatar"
import { watchShareByEmail } from "./shareByEmail"
import { watchPostEditedComment } from "./postEditedComment"
import { watchHideComment } from "./hideComment"
import { watchSsoSignOnStart } from "./ssoSignOn"
import { getAnalysis } from "./getAnalysis"
import { watchAdminLoginStart } from "./admin/adminLogin"
import { watchLogout } from "./logout"
import { watchUnsubscribeStart } from "./unsubscribe"
import { watchAdminLogout } from "./admin/adminLogut"
import { watchUpdateInfoPageStart } from "./admin/updateInfoPage"
import { watchDeleteInfoPageStart } from "./admin/deleteInfoPage"
import { watchCreateInfoPageStart } from "./admin/createInfoPage"
import { watchGetInfoPageByUrlStart } from "./admin/getInfoPageByUrl"
import { watchGetInfoPageByIdStart } from "./admin/getInfoPageById"
import { watchGetInfoPageListStart } from "./admin/getInfoPageList"
import { watchGetAminListStart } from "./admin/getAdminList"
import { watchGetAminInformationStart } from "./admin/getAdminInformation"
import { deleteAdminStart } from "./admin/deleteAdmin"
import { watchGetAnalysisListPageStart } from "./admin/getAnalysisList"
import { watchGetAdminAnalysisStart } from "./admin/getAdminAnalysis"
import { deleteAdminAnalysisStart } from "./admin/deleteAdminAnalysis"
import { watchGetUserListStart } from "./admin/getUsersList"
import { watchGetUserInformationStart } from "./admin/getUserInformation"
import { deleteUserStart } from "./admin/deleteUser"
import { watchGetAbuseReportsStart } from "./admin/getAbuseReports"
import { watchGetAnalysisReportsStart } from "./admin/getAnalysisReports"
import { watchGetAnalysisTopicsStart } from "./admin/getAnalysisTopics"
import { watchGetAnalystsStart } from "./admin/getAnalysts"
import { watchGetClaimsStart } from "./admin/getClaims"
import { restoreCommentStart } from "./admin/restoreComment"
import { watchUpdateAnalaysisStart } from "./admin/updateAnalysis"
import { assignCommentAnalystStart } from "./admin/assignCommentAnalyst"
import { assignReportAnalystStart } from "./admin/assignReportAnalyst"
import { deleteCommentStart } from "./admin/deleteComment"
import { watchEditComment } from "./admin/editComment"
import { watchGetCommentsStatusesStart } from "./admin/getCommentsStatuses"
import { watchGetAbuseReportsStatusesStart } from "./admin/getAbuseReportsStatuses"
import { watchSetCommentStatusRequestStart } from "./admin/setCommentStatus"
import { watchSetAbuseReportStatusRequestStart } from "./admin/setAbuseReportStatus"
import { postCommentReplyStart } from "./admin/postCommentReply"
import { watchHideReply } from "./admin/hideReply"
import { watchGetCalcDetailsStart } from "./admin/getCalcDetails"
import { watchSetAnalysisStatusStart } from "./admin/setAnalysisStatus"
import { watchUserEditComment } from "./userEditComment"
import { watchGetNodeTree } from "./admin/getNodeTree"
import { watchGetNodeTypes } from "./admin/getNodeTypes"
import { watchGetNodeFunctions } from "./admin/getNodeFunctions"
import { getSensitivityCalc } from "./getSensitivityCalculation"
import { watchCopyAnalysisStart } from "./admin/copyAnalysis"

export default function* rootSaga(): SagaIterator {
    yield all([
        fork(watchLoginStart),
        fork(watchAdminLoginStart),
        fork(watchSignUpStart),
        fork(watchSendConfirmCodeStart),
        fork(watchResetPasswordConfirmationStart),
        fork(watchResetPasswordStart),
        fork(watchSetNewPasswordStart),
        fork(watchSendConfirmTokenStart),
        fork(watchTopAnalysis),
        fork(watchTopUnpublishedAnalysis),
        fork(watchGetComments),
        fork(watchPostComment),
        fork(watchPostReply),
        fork(watchGetReplies),
        fork(watchVote),
        fork(watchPostReport),
        fork(watchSubEvidence),
        fork(watchResendConfirmEmail),
        fork(getShortAnalysis),
        fork(watchUpdateUserName),
        fork(watchUpdatePassword),
        fork(watchUpdateAvatar),
        fork(watchShareByEmail),
        fork(watchPostEditedComment),
        fork(watchHideComment),
        fork(watchSsoSignOnStart),
        fork(getAnalysis),
        fork(watchLogout),
        fork(watchAdminLogout),
        fork(watchUpdateInfoPageStart),
        fork(watchDeleteInfoPageStart),
        fork(watchCreateInfoPageStart),
        fork(watchGetInfoPageByUrlStart),
        fork(watchGetInfoPageByIdStart),
        fork(watchGetInfoPageListStart),
        fork(watchGetAminListStart),
        fork(watchGetAminInformationStart),
        fork(deleteAdminStart),
        fork(watchGetAdminAnalysisStart),
        fork(deleteAdminAnalysisStart),
        fork(watchGetUserListStart),
        fork(watchGetUserInformationStart),
        fork(deleteUserStart),
        fork(watchGetAnalysisListPageStart),
        fork(watchGetAbuseReportsStart),
        fork(watchGetAnalysisReportsStart),
        fork(watchGetAnalysisTopicsStart),
        fork(watchGetAnalystsStart),
        fork(watchGetClaimsStart),
        fork(restoreCommentStart),
        fork(watchUpdateAnalaysisStart),
        fork(assignCommentAnalystStart),
        fork(assignReportAnalystStart),
        fork(deleteCommentStart),
        fork(watchEditComment),
        fork(watchGetCommentsStatusesStart),
        fork(watchGetAbuseReportsStatusesStart),
        fork(watchSetCommentStatusRequestStart),
        fork(watchSetAbuseReportStatusRequestStart),
        fork(postCommentReplyStart),
        fork(watchHideReply),
        fork(watchGetCalcDetailsStart),
        fork(watchUserEditComment),
        fork(watchGetNodeTree),
        fork(watchGetNodeTypes),
        fork(watchGetNodeFunctions),
        fork(watchUnsubscribeStart),
        fork(watchSetAnalysisStatusStart),
        fork(getSensitivityCalc),
        fork(watchCopyAnalysisStart),
    ])
}
