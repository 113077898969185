import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { PostEditedCommentActionTypes, Replies } from "../actions/main/types"
import { Comment } from "../../types"
import { call, put } from "redux-saga/effects"
import { postEditedComment } from "../../services/main"
import { postEditedCommentActions } from "../actions/main/main"
import { getCommentsSelector, getRepliesSelector } from "../../helpers/selectors"
import * as Sentry from "@sentry/node"
import { AppState } from "../reducers"

export function* watchPostEditedComment(): SagaIterator {
    yield takeEvery(
        PostEditedCommentActionTypes.POST_EDITED_COMMENT_REQUEST,
        postEditedCommentRequest
    )
}

export function* postEditedCommentRequest({
    payload: { setLoading, body, resetForm, setEditingComment, type },
}: ReturnType<typeof postEditedCommentActions.request>): SagaIterator {
    try {
        setLoading(true)
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(postEditedComment, { body, type, access_token })

        const replies: Replies = yield select(getRepliesSelector)
        const comments: Comment[] = yield select(getCommentsSelector)

        const mapComment = (comment: Comment) =>
            comment.id === body.commentId ? { ...comment, text: body.text } : comment

        let mappedComments = comments
        if (type === "comment") {
            mappedComments = comments.map(mapComment)
        }

        let mappedReplies: Replies = replies
        if (type === "reply") {
            mappedReplies = {}

            Object.entries(replies).forEach(([key, comment]) => {
                mappedReplies[+key] = comment.map(mapComment)
            })
        }

        yield put(
            postEditedCommentActions.success({ comments: mappedComments, replies: mappedReplies })
        )

        setEditingComment(false)
        resetForm && resetForm({})
    } catch (e) {
        Sentry.captureException(e)
        yield put(postEditedCommentActions.failure(e))
    } finally {
        setLoading(false)
    }
}
