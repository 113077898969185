export type InfoPageReducerState = {
    adminInfoPage: InfoPageType
    viewInfoPage: InfoPageType
    infoPageList: InfoPageList
    loader: boolean
}

export type InfoPageList = {
    page: number
    count: number
    data: InfoPageListItem[]
}

export type InfoPageListItem = {
    id: number
    name: string
    url: string
}

export type CreateInfoPageRequest = {
    title: string
}

export type InfoPageType = {
    id?: number | null | string
    unpublished?: boolean
    url?: string
    title?: string
    image_url_desktop?: string
    image_url_tablet?: string
    image_url_mobile?: string
    body?: string
    share_image_url?: string | null
    share_url?: string
    share_title?: string
    share_description?: string
    errors?: Array<any>
    action?: () => void
    slug?: string
}

export enum CreateInfoPageActionTypes {
    CREATE_INFO_PAGE_REQUEST = "@@admin/infoPage/CREATE_INFO_PAGE_REQUEST",
    CREATE_INFO_PAGE_SUCCESS = "@@admin/infoPage/CREATE_INFO_PAGE_SUCCESS",
    CREATE_INFO_PAGE_FAILURE = "@@admin/infoPage/CREATE_INFO_PAGE_FAILURE",
}

export enum UpdateInfoPageActionTypes {
    UPDATE_INFO_PAGE_REQUEST = "@@admin/infoPage/UPDATE_INFO_PAGE_REQUEST",
    UPDATE_INFO_PAGE_SUCCESS = "@@admin/infoPage/UPDATE_INFO_PAGE_SUCCESS",
    UPDATE_INFO_PAGE_FAILURE = "@@admin/infoPage/UPDATE_INFO_PAGE_FAILURE",
}

export enum GetInfoPageByUrlActionTypes {
    GET_INFO_PAGE_BY_URL_REQUEST = "@@admin/infoPage/GET_INFO_PAGE_BY_URL_REQUEST",
    GET_INFO_PAGE_BY_URL_SUCCESS = "@@admin/infoPage/GET_INFO_PAGE_BY_URL_SUCCESS",
    GET_INFO_PAGE_BY_URL_FAILURE = "@@admin/infoPage/GET_INFO_PAGE_BY_URL_FAILURE",
}

export enum GetInfoPageByIdActionTypes {
    GET_INFO_PAGE_BY_ID_REQUEST = "@@admin/infoPage/GET_INFO_PAGE_BY_ID_REQUEST",
    GET_INFO_PAGE_BY_ID_SUCCESS = "@@admin/infoPage/GET_INFO_PAGE_BY_ID_SUCCESS",
    GET_INFO_PAGE_BY_ID_FAILURE = "@@admin/infoPage/GET_INFO_PAGE_BY_ID_FAILURE",
}

export enum GetInfoPageListActionTypes {
    GET_INFO_PAGE_LIST_REQUEST = "@@admin/infoPage/GET_INFO_PAGE_LIST_REQUEST",
    GET_INFO_PAGE_LIST_SUCCESS = "@@admin/infoPage/GET_INFO_PAGE_LIST_SUCCESS",
    GET_INFO_PAGE_LIST_FAILURE = "@@admin/infoPage/GET_INFO_PAGE_LIST_FAILURE",
}

export enum DeleteInfoPageActionTypes {
    DELETE_INFO_PAGE_REQUEST = "@@admin/infoPage/DELETE_INFO_PAGE_REQUEST",
    DELETE_INFO_PAGE_SUCCESS = "@@admin/infoPage/DELETE_INFO_PAGE_SUCCESS",
    DELETE_INFO_PAGE_FAILURE = "@@admin/infoPage/DELETE_INFO_PAGE_FAILURE",
}
