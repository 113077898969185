import { createAsyncAction } from "typesafe-actions"
import {
    UserList,
    UserItemView,
    GetUserListActionTypes,
    GetUserInfoActionTypes,
    DeleteUserActionTypes,
} from "./types"

export const getUserListActions = createAsyncAction(
    GetUserListActionTypes.GET_USER_LIST_REQUEST,
    GetUserListActionTypes.GET_USER_LIST_SUCCESS,
    GetUserListActionTypes.GET_USER_LIST_FAILURE
)<{ limit: number; page: number }, UserList, { error: any }>()

export const getUserInfoActions = createAsyncAction(
    GetUserInfoActionTypes.GET_USER_INFO_REQUEST,
    GetUserInfoActionTypes.GET_USER_INFO_SUCCESS,
    GetUserInfoActionTypes.GET_USER_INFO_FAILURE
)<number | string, UserItemView, { error: any }>()

export const deleteUserActions = createAsyncAction(
    DeleteUserActionTypes.DELETE_USER_REQUEST,
    DeleteUserActionTypes.DELETE_USER_SUCCESS,
    DeleteUserActionTypes.DELETE_USER_FAILURE
)<
    {
        id: number | string
        action?: (page: number) => void
        page?: number
        limit?: number | undefined
        id_option?: string
        id_value?: number
        email_option?: string
        email_value?: string
        name_option?: string
        name_value?: string
        link_option?: string
        link_value?: string
        sign_in_count_option?: string
        sign_in_count_value?: number
        admin_user_email_option?: string
        adin_user_email_value?: string
    },
    undefined,
    { error: any }
>()
