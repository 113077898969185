import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { PostReportActionTypes } from "../actions/main/types"
import { postReportActions } from "../actions/main/main"
import { call, put } from "redux-saga/effects"
import { postReport } from "../../services/main"
import { removeModal, addModal } from "../actions/ui/ui"
import { REPORT_COMMENT_MODAL, SUCCESS_REPORT_MODAL } from "../../constants/modals"
import * as Sentry from "@sentry/node"

export function* watchPostReport(): SagaIterator {
    yield takeEvery(PostReportActionTypes.POST_REPORT_REQUEST, postReportRequest)
}

export function* postReportRequest(payload: ReturnType<typeof postReportActions.request>) {
    try {
        yield call(postReport, payload)
        yield put(removeModal(REPORT_COMMENT_MODAL))
        yield put(
            addModal({
                id: SUCCESS_REPORT_MODAL,
                size: "auto",
                children: "Your report has been filed!",
                hideLogo: true,
            })
        )
    } catch (e) {
        Sentry.captureException(e)
        yield put(postReportActions.failure(e))
    }
}
