import { GetAnalysisTopicsActionTypes } from "src/redux/actions/admin/ugc/types"
import { getAnalysisTopicsActionTypes } from "src/redux/actions/admin/ugc/ugc"
import { getAnalysisTopics } from "src/services/admin/ugc"
import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "src/redux/reducers"
import { call, put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"

export function* watchGetAnalysisTopicsStart(): SagaIterator {
    yield takeEvery(
        GetAnalysisTopicsActionTypes.GET_ANALYSIS_TOPICS_REQUEST,
        getAnalysisTopicsRequest
    )
}

export function* getAnalysisTopicsRequest(): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getAnalysisTopics, { access_token })
        yield put(getAnalysisTopicsActionTypes.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getAnalysisTopicsActionTypes.failure(e))
    }
}
