import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { getAnalysisListActions } from "src/redux/actions/admin/analysis/analysis"
import { call, put } from "redux-saga/effects"
import { getAnalysisList } from "src/services/admin/analysis"
import { message } from "antd"
import { AppState } from "src/redux/reducers"
import * as Sentry from "@sentry/node"
import { GetAnalysisListActionTypes } from "src/redux/actions/admin/analysis/types"

export function* watchGetAnalysisListPageStart(): SagaIterator {
    yield takeEvery(
        GetAnalysisListActionTypes.GET_ANALYSIS_LIST_REQUEST,
        getAnalysisListPageRequest
    )
}

export function* getAnalysisListPageRequest({
    payload,
}: ReturnType<typeof getAnalysisListActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const {
            result: { data, count, page },
        } = yield call(getAnalysisList, { ...payload, access_token })
        yield put(getAnalysisListActions.success({ data, count, page }))
    } catch (e) {
        message.error(e.message)
        Sentry.captureException(e)
        yield put(getAnalysisListActions.failure(e))
    }
}
