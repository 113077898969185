import { useEffect } from "react"
import { NextPage } from "next"
import { Provider } from "react-redux"
import { useStore } from "src/redux/store"
import * as Sentry from "@sentry/node"
import { GlobalStyle } from "src/styles/global.styles"
import { ThemeProvider } from "styled-components"
import { theme } from "../src/styles/theme.styles"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import { isBrowser } from "../src/helpers"
import { Store } from "redux"
import Router from "next/router"
import { useState } from "react"
import LoaderWholePage from "../src/reusableComponents/LoaderWholePage"
import * as ga from "src/lib/ga"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "production",
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    })
}

let store: Store<any>

export const getToken: () => string | undefined = () =>
    store?.getState && store.getState()?.user?.access_token

const MyApp: NextPage<{ Component: any; pageProps: any; err: any }> = ({
    Component,
    pageProps,
    err,
}) => {
    useEffect(() => {
        const handleRouteChange = (url: any) => {
            ga.pageview(url)
        }
        //When the component is mounted, subscribe to router changes
        //and log those page views
        Router.events.on("routeChangeComplete", handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            Router.events.off("routeChangeComplete", handleRouteChange)
        }
    }, [Router.events])

    store = useStore(pageProps.initialReduxState)

    const [loading, setLoading] = useState(false)

    if (isBrowser()) {
        window.history.scrollRestoration = "manual"
    }

    useEffect(() => {
        Router.events.on("routeChangeStart", () => setLoading(true))
        Router.events.on("routeChangeComplete", () => {
            setLoading(false)
        })
        Router.events.on("routeChangeError", () => {
            setLoading(false)
        })
    }, [])

    const persistor = persistStore(store)

    return (
        <Provider store={store}>
            {isBrowser() ? (
                <PersistGate persistor={persistor} loading={null}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle />
                        <Component {...pageProps} err={err} />
                        {loading && <LoaderWholePage />}
                    </ThemeProvider>
                </PersistGate>
            ) : (
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <Component {...pageProps} err={err} />
                </ThemeProvider>
            )}
        </Provider>
    )
}

export default MyApp
