import { useDispatch } from "react-redux"

// components
import ButtonPrimary from "src/reusableComponents/ButtonPrimary"

// utils
import { removeModal } from "src/redux/actions/ui/ui"
import { SUCCESS_UNSUBSCRIBE_MODAL } from "src/constants/modals"

// styles
import { StyledBodyMain } from "src/styles/Text/Text.styles"
import { StyledModal } from "./SuccessUnsubscribeModal.styles"

const SuccessUnsubscribeModal = () => {
    const dispatch = useDispatch()

    const onContinue = () => {
        dispatch(removeModal(SUCCESS_UNSUBSCRIBE_MODAL))
    }

    return (
        <StyledModal>
            <StyledBodyMain weight={600}>You've successfully unsubscribed.</StyledBodyMain>
            <div className="buttonsBlock">
                <ButtonPrimary block onClick={onContinue}>
                    Continue
                </ButtonPrimary>
            </div>
        </StyledModal>
    )
}

export default SuccessUnsubscribeModal
