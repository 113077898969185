import { SagaIterator } from "@redux-saga/types"
import { takeEvery } from "@redux-saga/core/effects"
import { UpdateUserNameActionTypes } from "../actions/user/types"
import { updateUserNameActions } from "../actions/user/user"
import { call, put } from "redux-saga/effects"
import { updateName } from "../../services/user"
import * as Sentry from "@sentry/node"

export function* watchUpdateUserName(): SagaIterator {
    yield takeEvery(UpdateUserNameActionTypes.UPDATE_USER_NAME_REQUEST, updateUserNameRequest)
}

export function* updateUserNameRequest(
    payload: ReturnType<typeof updateUserNameActions.request>
): SagaIterator {
    const {
        payload: { setErrors, name, setFieldValue, setEditName },
    } = payload
    try {
        yield call(updateName, payload)
        yield put(updateUserNameActions.success({ name }))

        setFieldValue("name", name)
        setEditName(false)
    } catch (e) {
        Sentry.captureException(e)
        setErrors(e.result)
        yield put(updateUserNameActions.failure(e.result))
    }
}
