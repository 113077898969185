import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "../../reducers"
import { put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import { message } from "antd"
import { DeleteAdminAnalysisActionTypes } from "src/redux/actions/admin/analysis/types"
import {
    deleteAdminAnalysisActions,
    getAnalysisListActions,
} from "src/redux/actions/admin/analysis/analysis"
import { deleteAnalysis, getAnalysisList } from "src/services/admin/analysis"

import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* deleteAdminAnalysisStart(): SagaIterator {
    yield takeEvery(
        DeleteAdminAnalysisActionTypes.DELETE_ADMIN_ANALYSIS_REQUEST,
        deleteAdminAnalysisRequest
    )
}

export function* deleteAdminAnalysisRequest({
    payload: { id, page, limit, action },
}: ReturnType<typeof deleteAdminAnalysisActions.request>): SagaIterator {
    try {
        const { access_token } = yield select((state: AppState) => state?.admin)
        yield call(deleteAnalysis, { id, access_token })

        const {
            result: { data, count },
        } = yield call(getAnalysisList, { page: page, limit: limit, access_token })
        yield put(getAnalysisListActions.success({ data, count, page }))

        yield put(deleteAdminAnalysisActions.success())
        if (action) {
            yield call(action)
        }
        message.success("Analysis deleted")
    } catch (e) {
        message.error("Something went wrong")
        Sentry.captureException(e)
        yield put(deleteAdminAnalysisActions.failure(e))
    }
}
