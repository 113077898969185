import { SagaIterator } from "@redux-saga/types"
import { TopUnpublishedAnalysisActionTypes } from "../actions/main/types"
import { getTopUnpublishedAnalysis } from "../../services/main"
import { put, takeEvery } from "@redux-saga/core/effects"
import { getTopUnpublishedAnalysisActions } from "../actions/main/main"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"

const call: any = Effects.call

export function* watchTopUnpublishedAnalysis(): SagaIterator {
    yield takeEvery(
        TopUnpublishedAnalysisActionTypes.TOP_UNPUBLISHED_ANALYSIS_REQUEST,
        topUnpublishedAnalysisRequest
    )
}

export function* topUnpublishedAnalysisRequest({
    payload,
}: ReturnType<typeof getTopUnpublishedAnalysisActions.request>): SagaIterator {
    try {
        const { result } = yield call(getTopUnpublishedAnalysis, payload)
        yield put(getTopUnpublishedAnalysisActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getTopUnpublishedAnalysisActions.failure(e.result))
    }
}
