import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { CopyAnalysisActionTypes } from "src/redux/actions/admin/analysis/types"
import { copyAnalysisActions } from "src/redux/actions/admin/analysis/analysis"
import { put } from "redux-saga/effects"
import { copyAnalysis } from "src/services/admin/analysis"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"
import { AppState } from "src/redux/reducers"
import { message } from "antd"

const call: any = Effects.call

export function* watchCopyAnalysisStart(): SagaIterator {
    yield takeEvery(CopyAnalysisActionTypes.COPY_ANALYSIS_REQUEST, copyAnalysisRequest)
}

export function* copyAnalysisRequest({
    payload,
}: ReturnType<typeof copyAnalysisActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        yield call(copyAnalysis, {
            analysisId: payload.analysisId,
            access_token,
        })
        message.success("Analysis was copied")
        payload.action()

        yield put(copyAnalysisActions.success())
    } catch (e) {
        payload.action()
        message.error(e.message || "Something went wrong")
        Sentry.captureException(e)
        yield put(copyAnalysisActions.failure(e))
    }
}
