import { SagaIterator } from "@redux-saga/types"
import { TopAnalysisActionTypes } from "../actions/main/types"
import { getTopAnalysis } from "../../services/main"
import { put, takeEvery } from "@redux-saga/core/effects"
import { getTopAnalysisActions } from "../actions/main/main"
import * as Sentry from "@sentry/node"
import * as Effects from "redux-saga/effects"
const call: any = Effects.call

export function* watchTopAnalysis(): SagaIterator {
    yield takeEvery(TopAnalysisActionTypes.TOP_ANALYSIS_REQUEST, topAnalysisRequest)
}

export function* topAnalysisRequest({
    payload,
}: ReturnType<typeof getTopAnalysisActions.request>): SagaIterator {
    try {
        const { result } = yield call(getTopAnalysis, payload)

        yield put(getTopAnalysisActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getTopAnalysisActions.failure(e.result))
    }
}
