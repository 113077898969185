import styled from "styled-components"

export const StyledSetNewPasswordModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 286px;
    }

    .marginBottom {
        margin-bottom: 30px;
    }

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > * {
            width: 45%;
        }
    }

    .block {
        width: 100%;
    }

    @media (max-width: 575px) {
        form {
            width: 100%;
        }
    }
`
