import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { PostReplyActionTypes, Replies } from "../actions/main/types"
import { Comment } from "../../types"
import { postReplyActions } from "../actions/main/main"
import { call, put } from "redux-saga/effects"
import { postReply } from "../../services/main"
import { AppState } from "../reducers"
import { getCommentsSelector, getRepliesSelector } from "../../helpers/selectors"
import * as Sentry from "@sentry/node"

const getUser = ({ user }: AppState) => user

export function* watchPostReply(): SagaIterator {
    yield takeEvery(PostReplyActionTypes.POST_REPLY_REQUEST, postReplyRequest)
}

export function* postReplyRequest({
    payload: { setLoading, body, resetForm, currentUserId },
}: ReturnType<typeof postReplyActions.request>) {
    try {
        setLoading(true)
        const { result } = yield call(postReply, { body })

        const replies: Replies = yield select(getRepliesSelector)
        const commentsFromStore: Comment[] = yield select(getCommentsSelector)

        const { name, image, isAdmin }: { name: string; image: string; isAdmin: boolean } =
            yield select(getUser)

        resetForm && resetForm({})

        replies[body.commentId] = [
            {
                ...result,
                author: { name, image, id: currentUserId },
                is_admin: isAdmin,
                votes: { up: 0, down: 0 },
            },
            ...(replies[body.commentId] || []),
        ]

        const comments = commentsFromStore.map(({ replies_count = 0, ...comment }) =>
            comment.id === body.commentId
                ? { ...comment, replies_count: ++replies_count }
                : { ...comment, replies_count }
        )

        yield put(postReplyActions.success({ replies, comments }))
    } catch (e) {
        Sentry.captureException(e)
        yield put(postReplyActions.failure(e))
    } finally {
        setLoading(false)
    }
}
