import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { AppState } from "../../reducers"
import { call, put } from "redux-saga/effects"
import * as Sentry from "@sentry/node"
import { getAdminAnalysisActions } from "../../actions/admin/analysis/analysis"
import { GetAdminAnalysisActionTypes } from "../../actions/admin/analysis/types"
import { getAdminAnalysis } from "../../../services/admin/analysis"

export function* watchGetAdminAnalysisStart(): SagaIterator {
    yield takeEvery(GetAdminAnalysisActionTypes.GET_ADMIN_ANALYSIS_REQUEST, getAdminAnalysisRequest)
}

export function* getAdminAnalysisRequest({
    payload: slug,
}: ReturnType<typeof getAdminAnalysisActions.request>): SagaIterator {
    try {
        const access_token = yield select((state: AppState) => state?.admin?.access_token)
        const { result } = yield call(getAdminAnalysis, { slug, access_token })

        yield put(getAdminAnalysisActions.success(result))
    } catch (e) {
        Sentry.captureException(e)
        yield put(getAdminAnalysisActions.failure(e))
    }
}
