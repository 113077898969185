import { SagaIterator } from "@redux-saga/types"
import { select, takeEvery } from "@redux-saga/core/effects"
import { call } from "redux-saga/effects"
import { setStore } from "../../store"
import { LogoutActionType } from "../../actions/admin/adminUser/types"
import { AppState } from "../../reducers"

export function* watchAdminLogout(): SagaIterator {
    yield takeEvery(LogoutActionType.LOGOUT, logoutRequest)
}

export function* logoutRequest(): SagaIterator {
    localStorage.removeItem("persist:adminUser")

    const { admin, ...store } = yield select((store: AppState) => store)

    yield call(setStore, store)
}
